import React from 'react';
import { findIndex as _findIndex } from 'lodash';
import {
  ForgeScaffold,
  ForgeSelect,
  ForgeButton,
  ForgeIcon,
  ForgeButtonToggleGroup,
  ForgeButtonToggle,
  ForgeDivider
} from '@tylertech/forge-react';
import I18n from 'common/i18n';
import EnumerationChoice from './EnumerationChoice';
import { replaceAtIndex, determineAlphabeticalSortOrder, SortOrder, alphabetize, discoverDuplicates } from './helpers';
import { FieldT, OptionByParent } from 'common/types/metadataTemplate';
import { ColumnRef, Expr, FunCall, Let, SoQLType, TableQualifier } from 'common/types/soql';
import { FieldWithParentDefaultExpressionBuilder } from 'common/dsmapi/metadataTemplate';

const t = (translationKey: string, options: { [key: string]: any } = {}) =>
  I18n.t(translationKey, { scope: 'metadata_templates', ...options });

export interface FieldWithParentEditorProps {
  field: FieldT;
  qualifier: TableQualifier;
  parentField: ColumnRef;
  parentDisplayName: string;
  parentOptions: string[];
  optionsByParent: OptionByParent[];
  withLabels?: boolean;
  parentLabels: string[];
  updateLegacyLabels: (labels: string[]) => void;
  makeNewFieldExpression: (newOptionsByParent: OptionByParent[]) => FunCall | Let;
  buildDefaultFieldExpression: FieldWithParentDefaultExpressionBuilder;
  updateExpr: ({
    expr,
    newLabels,
    newType
  }: {
    expr: Expr;
    newLabels?: string[];
    newType?: SoQLType;
  }) => void;
}

const FieldWithParentEditor: React.FunctionComponent<FieldWithParentEditorProps> = ({
  field,
  qualifier,
  parentField,
  parentDisplayName,
  parentOptions,
  parentLabels,
  optionsByParent,
  withLabels,
  updateLegacyLabels,
  makeNewFieldExpression,
  buildDefaultFieldExpression,
  updateExpr
}) => {
  // We only want to show parent values that don't already have a block in the dropdown menu
  // so users can't duplicate blocks.
  const availableParentOptions: Array<{ value: string; label: string }> = parentOptions.flatMap(
    (parentFieldValue, index) => {
      if (optionsByParent.findIndex((obp) => obp.parentValue === parentFieldValue) < 0) {
        return [{ value: parentFieldValue, label: parentLabels[index] }];
      } else {
        return [];
      }
    }
  );

  const onAddOption = (parentFieldValue: string) => {
    const currentOptByParentIdx = optionsByParent.findIndex((obp) => obp.parentValue === parentFieldValue);
    const currentOptByParent = optionsByParent[currentOptByParentIdx];
    if (currentOptByParent) {
      const newOptionByParent = {
        ...currentOptByParent,
        options: [...currentOptByParent.options, '']
      };
      const newOptionsByParent = replaceAtIndex(optionsByParent, newOptionByParent, currentOptByParentIdx);
      updateExpr({ expr: makeNewFieldExpression(newOptionsByParent) });
    } else {
      const newOptionByParent = {
        parentValue: parentFieldValue,
        options: [''],
        labels: [] // this will get auto-populated by intoMetadataComponents
      };
      updateExpr({
        expr: makeNewFieldExpression([...optionsByParent, newOptionByParent])
      });
    }
  };

  const onRemoveOption = (blockIndexToRemove: number, parentFieldValue: string) => () => {
    if (blockIndexToRemove < 0) return;
    const currentOptByParentIdx = optionsByParent.findIndex((obp) => obp.parentValue === parentFieldValue);
    const currentOptByParent = optionsByParent[currentOptByParentIdx];

    if (currentOptByParent.options.length === 1 && optionsByParent.length === 1) {
      // We're removing the only option, just reset back to the default state.
      updateExpr({
        expr: buildDefaultFieldExpression(
          field.field_name,
          field.display_name,
          qualifier,
          parentField.value,
          parentDisplayName,
          parentOptions[0]
        ),
        newLabels: []
      });
    } else {
      let newOptionsByParent;
      if (currentOptByParent) {
        const newOptions = currentOptByParent.options.filter((_opt, i) => i !== blockIndexToRemove);
        if (newOptions.length > 0) {
          const newOptionByParent = {
            ...currentOptByParent,
            options: newOptions,
            labels: currentOptByParent.labels.filter((_labels, i) => i !== blockIndexToRemove)
          };
          newOptionsByParent = replaceAtIndex(optionsByParent, newOptionByParent, currentOptByParentIdx);
        } else {
          newOptionsByParent = optionsByParent.filter((_obp, i) => i !== currentOptByParentIdx);
        }

        if (withLabels) {
          const newLabels = newOptionsByParent.flatMap((obp) => obp.labels);
          updateExpr({ expr: makeNewFieldExpression(newOptionsByParent), newLabels });
        } else {
          updateExpr({ expr: makeNewFieldExpression(newOptionsByParent) });
        }
      }
    }
  };

  const onChangeOption =
    (internalBlockIndex: number, parentFieldValue: string) => (event: React.FormEvent<HTMLInputElement>) => {
      const currentOptByParentIdx = optionsByParent.findIndex((obp) => obp.parentValue === parentFieldValue);
      const currentOptByParent = optionsByParent[currentOptByParentIdx];
      if (currentOptByParent) {
        const newOptionByParent = {
          ...currentOptByParent,
          options: replaceAtIndex(currentOptByParent.options, event.currentTarget.value, internalBlockIndex)
        };
        const newOptionsByParent = replaceAtIndex(optionsByParent, newOptionByParent, currentOptByParentIdx);
        updateExpr({ expr: makeNewFieldExpression(newOptionsByParent) });
      }
    };

  const onChangeParentOption = (oldParentValue: string, newParentValue: string) => {
    const currentOptByParentIdx = optionsByParent.findIndex((obp) => obp.parentValue === oldParentValue);
    const currentOptByParent = optionsByParent[currentOptByParentIdx];
    if (currentOptByParent) {
      const newOptByParent = {
        ...currentOptByParent,
        parentValue: newParentValue
      };
      const newOptionsByParent = replaceAtIndex(optionsByParent, newOptByParent, currentOptByParentIdx);
      updateExpr({ expr: makeNewFieldExpression(newOptionsByParent) });
    }
  };

  const onChangeLabel = (index: number) => (e: React.FormEvent<HTMLInputElement>) => {
    const labels: string[] = optionsByParent.flatMap((opb) => opb.labels);
    updateLegacyLabels(replaceAtIndex(labels, e.currentTarget.value, index));
  };

  const onAlphabetize = (requestedSortOrder: SortOrder, parentFieldValue: string) => {
    const currentOptByParentIdx = optionsByParent.findIndex((obp) => obp.parentValue === parentFieldValue);
    const currentOptByParent = optionsByParent[currentOptByParentIdx];

    if (currentOptByParent) {
      const { ordered, orderedLabels } = alphabetize(
        currentOptByParent.options,
        currentOptByParent.labels,
        requestedSortOrder === SortOrder.ASCENDING
      );
      const newOptionByParent = {
        ...currentOptByParent,
        options: ordered,
        labels: orderedLabels
      };
      const newOptionsByParent = replaceAtIndex(optionsByParent, newOptionByParent, currentOptByParentIdx);

      if (withLabels) {
        const sortedLabels = newOptionsByParent.flatMap((obp) => obp.labels);
        updateExpr({
          expr: makeNewFieldExpression(newOptionsByParent),
          newLabels: sortedLabels
        });
      } else {
        updateExpr({ expr: makeNewFieldExpression(newOptionsByParent) });
      }
    }
  };

  const canAddMoreBlocks = availableParentOptions.length > 0;

  const parentBlocks: Array<JSX.Element> = [];

  let parentBlockCount = 0;
  let overallIndex = -1;

  const allOptionsCount = optionsByParent.flatMap((obp) => obp.options).length;

  optionsByParent.forEach(({ options: childOptions, parentValue }) => {
    parentBlockCount++;
    const showSortToggle = childOptions.length > 1;
    const alphabeticalSortOrder = determineAlphabeticalSortOrder(childOptions);
    const duplicateOptions = discoverDuplicates(childOptions);

    const optionsList = childOptions.map((childOption, internalBlockIndex) => {
      overallIndex++;
      const isDeletable =
        allOptionsCount > 1 || optionsByParent.length > 1 || childOption !== '' || !!field.legacy_labels[0];

      return (
        <EnumerationChoice
          key={overallIndex}
          withLabels={withLabels || false}
          isDuplicate={duplicateOptions[childOption] || false}
          field={field}
          choice={childOption}
          onChangeOption={onChangeOption(internalBlockIndex, parentValue)}
          onChangeLabel={onChangeLabel(overallIndex)}
          onRemoveOption={onRemoveOption(internalBlockIndex, parentValue)}
          index={overallIndex}
          blockIndex={internalBlockIndex}
          isDeletable={isDeletable}
        />
      );
    });
    const indexOfLabel = _findIndex(parentOptions, (parentOption) => parentOption === parentValue);
    // Include the block's parent field value in the list of options shown in the block's dropdown
    const selectOptions = [
      ...availableParentOptions,
      { value: parentValue, label: parentLabels[indexOfLabel] ? parentLabels[indexOfLabel] : parentValue}
    ];

    // We don't want to show the divider at the bottom of the list if the "add parent" button isn't there
    const showDivider = availableParentOptions.length !== 0 || parentBlockCount !== optionsByParent.length;

    parentBlocks.push(
      <div className="parent-option-block" key={parentValue}>
        <ForgeScaffold>
          <div className="parent-option-block-body" slot="body">
            <div className="parent-option-selector">
              <div className="parent-option-selector-label">{t('select_with_parent.parent_option')}</div>
              <ForgeSelect
                label={parentField.value}
                value={parentValue}
                options={selectOptions}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeParentOption(parentValue, event.target.value)
                }
                data-testid="parent-for-block-selector"
              >
                <span slot="helper-text">{t('select_with_parent.parent_for_block_selector_help_text')}</span>
              </ForgeSelect>
            </div>
            <div className="child-options">
              <div className="child-options-header">
                <span className="child-options-label">{t('select_with_parent.child_options')}</span>
                <div>
                  {showSortToggle && (
                    <ForgeButtonToggleGroup dense value={alphabeticalSortOrder}>
                      <ForgeButtonToggle
                        onClick={() => onAlphabetize(SortOrder.ASCENDING, parentValue)}
                        data-testid={`sort-alphabetical-ascending-button-for-${parentValue}`}
                        aria-label={t('sort_options_ascending_button_label')}
                        value={SortOrder.ASCENDING}
                      >
                        <ForgeIcon name="sort_alphabetical_ascending" />
                      </ForgeButtonToggle>
                      <ForgeButtonToggle
                        onClick={() => onAlphabetize(SortOrder.DESCENDING, parentValue)}
                        data-testid={`sort-alphabetical-descending-button-for-${parentValue}`}
                        aria-label={t('sort_options_descending_button_label')}
                        value={SortOrder.DESCENDING}
                      >
                        <ForgeIcon name="sort_alphabetical_descending" />
                      </ForgeButtonToggle>
                    </ForgeButtonToggleGroup>
                  )}
                </div>
              </div>
              <div className="child-options-list">
                {optionsList}
                <ForgeButton>
                  <button
                    type="button"
                    onClick={() => onAddOption(parentValue)}
                    data-testid="add-new-option-button"
                  >
                    <span>{t('select_with_parent.add_option')}</span>
                    <ForgeIcon name="add_circle" />
                  </button>
                </ForgeButton>
              </div>
            </div>
          </div>
          <div className="parent-option-block-footer" slot="footer">
            {showDivider && <ForgeDivider />}
          </div>
        </ForgeScaffold>
      </div>
    );
  });

  return (
    <div className="select-with-parent-editor">
      <ForgeScaffold>
        <div slot="header">
          <h6>{t('dropdown_options_header')}</h6>
        </div>
        <div slot="body">{parentBlocks}</div>
        <div className="select-with-parent-list-footer" slot="footer">
          {canAddMoreBlocks && (
            <ForgeButton>
              <button
                type="button"
                onClick={() => onAddOption(availableParentOptions[0].value)}
                data-testid="add-new-parent-block-select"
              >
                <span>{t('select_with_parent.add_another_parent')}</span>
                <ForgeIcon name="add_circle" />
              </button>
            </ForgeButton>
          )}
        </div>
      </ForgeScaffold>
    </div>
  );
};

export default FieldWithParentEditor;
