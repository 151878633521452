import 'common/components/CompilerResult/compiler-result.scss';
import { Json } from 'common/components/ObjectEditor';
import { ColumnLike, defaultMatcher } from 'common/components/SoQLDocs';
import ColumnDoc from 'common/components/SoQLDocs/ColumnDoc';
import FunctionDoc from 'common/components/SoQLDocs/FunctionDoc';
import uuid from 'uuid';
import {
  deriveEnumerationFromField,
  fieldDisplayName,
} from 'common/dsmapi/metadataTemplate';
import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';
import { CompilationStatus } from 'common/types/compiler';
import { PhxChannel, TransformResult } from 'common/types/dsmapi';
import { FieldT, MetadataTemplate } from 'common/types/metadataTemplate';
import {
  Expr,
  FunSpec,
  isExpressionEqualIgnoringPosition,
  SoQLType,
  TableQualifier,
  TypedSoQLColumnRef
} from 'common/types/soql';
import _ from 'lodash';
import {
  ActionTypes,
  AppState,
  Dispatcher,
  FieldCompilation,
  compilationOk,
  compilationStarted,
  fatalCompilationError
} from 'metadataTemplates/store';
import { TemplateChannelTimeout, typedUniqColumnRefs } from 'metadataTemplates/util';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Option, option } from 'ts-option';
import EnumerationEditor from './EnumerationEditor';
import EnumerationificationEditor from './EnumerationificationEditor';
import './field-editor.scss';
import FieldDeletionButton from './FieldDeletionButton';
import PublicPrivateEditor from './PublicPrivateEditor';
import RequirednessEditor from './RequirednessEditor';
import FieldValidation, { ExternalProps as FieldValidationProps } from './FieldValidation';
import {
  ForgeButton,
  ForgeCard,
  ForgeDivider,
  ForgeIcon,
  ForgeScaffold,
  ForgeToolbar
} from '@tylertech/forge-react';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';
import ParentSelector from './ParentSelector';
import RestrictednessEditor from './RestrictednessEditor';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

export const renderFunction = (name: string, fs: FunSpec[]) => (abbreviated: boolean) => {
  return <FunctionDoc impls={fs} name={name} abbreviated={abbreviated} />;
};

export const renderColumn = (column: ColumnLike) => (abbreviated: boolean) => {
  return <ColumnDoc column={column} abbreviated={abbreviated} />;
};

export const FieldEditor: React.FunctionComponent<Props> = ({
  template,
  compilationResult,
  qualifier,
  field,
  scope,
  inputValues,
  transformResult,
  compile,
  compileAst,
  updateLegacyLabels,
  updateInputType,
  evaluate,
  deleteField
}) => {
  const enumeration = deriveEnumerationFromField(qualifier, field);

  const busy = compilationResult
    .map((compilation) => compilation.type === CompilationStatus.Started)
    .getOrElseValue(false);

  const isCompilationError = compilationResult
    .map((compilation) => compilation.type === CompilationStatus.Failed)
    .getOrElseValue(false);

  const matcher = useMemo(() => defaultMatcher(scope, [], renderFunction, renderColumn), scope);

  const [showValidationDialog, setShowValidationDialog] = useState(false);

  const compileAndToast = (expr: string) => {
    compile(expr);
    showToastNow({
      type: ToastType.FORGE_SUCCESS,
      content: t('metadata_updated')
    });
  };

  const fieldValidationDialog = () => {
    const fieldValidationProps: FieldValidationProps = {
      onDismiss: () => setShowValidationDialog(false),
      compilationResult,
      field,
      template,
      qualifier,
      updateInputType, // TODO Move me to the component
      enumeration,
      scope,
      matcher,
      onSoQLChange: compileAndToast
    };

    return <FieldValidation {...fieldValidationProps} />;
  };

  const soqlEnabled = FeatureFlags.value('enable_soql_metadata_validation');
  const canModifyPermissions = !(field.field_name === 'name' && field.is_builtin);

  return (
    <div className="field-editor">
      <ForgeCard outlined={true}>
        <ForgeScaffold>
          <ForgeToolbar slot="header" className={'field-editor-header'}>
            <h3 slot="start">{fieldDisplayName(field)}</h3>
            <div slot="end">
              <FieldDeletionButton
                busy={busy}
                template={template}
                qualifier={qualifier}
                field={field}
                deleteField={deleteField}
              />
              {soqlEnabled && (
                <ForgeButton
                  type={'outlined'}
                  className={'field-editor-buttons'}
                  data-testid="field-editor-test-button"
                >
                  <button onClick={() => setShowValidationDialog(true)}>
                    <ForgeIcon name="settings" />
                    {t('validation')}
                  </button>
                </ForgeButton>
              )}
            </div>
          </ForgeToolbar>
          <div slot="body" className={'field-editor-body'}>
            <div className="input-wrapper">
              <EnumerationificationEditor
                template={template}
                qualifier={qualifier}
                field={field}
                busy={busy}
                disabled={isCompilationError}
                updateExpr={compileAst}
              />
              <ParentSelector
                template={template}
                field={field}
                parsedExpression={field.parsed_expr}
                qualifier={qualifier}
                updateExpr={compileAst}
              />
            </div>
          </div>
          {canModifyPermissions && <div slot="body-right" className="field-editor-body-right">
            <h6>{t('field_permissions')}</h6>
            {FeatureFlags.value('enable_restricted_metadata') && (
              <>
                <RestrictednessEditor busy={busy} template={template} qualifier={qualifier} field={field} />
                <ForgeDivider className='divider' />
              </>
            )}
            <RequirednessEditor
              qualifier={qualifier}
              field={field}
              updateExpr={compileAst}
            />
            <ForgeDivider className="divider" />
            <PublicPrivateEditor busy={busy} template={template} qualifier={qualifier} field={field} />
          </div>}
          <div slot="footer">
            <EnumerationEditor
              field={field}
              qualifier={qualifier}
              updateExpr={compileAst}
              updateLegacyLabels={updateLegacyLabels}
              withLabels={FeatureFlags.value('enhance_custom_metadata')}
              template={template}
            />
          </div>
        </ForgeScaffold>
      </ForgeCard>
      {showValidationDialog && fieldValidationDialog()}
    </div>
  );
};

// Need to create this here outside of the render loop, or else
// we get a new debounced function on each props change, which
// is useless
const debounceCompilation = _.debounce((what: () => unknown) => {
  what();
}, 250);

export type Evaluatable = {
  columnRef: TypedSoQLColumnRef;
  value: string | Json | null;
}[];

interface ExternalProps {
  qualifier: TableQualifier;
  field: FieldT;
  template: MetadataTemplate;
  deleteField: () => void;
}
interface StateProps {
  chan: PhxChannel;
  template: MetadataTemplate;
  qualifier: TableQualifier;
  field: FieldT;
  scope: FunSpec[];
  compilationResult: Option<FieldCompilation>;
  deleteField: () => void;
  inputValues: Evaluatable;
  transformResult: TransformResult | null;
}

interface DispatchProps {}
type Props = StateProps &
  DispatchProps & {
    compile: (expr: string) => void;
    compileAst: ({
      expr,
      newLabels,
      newType
    }: {
      expr: Expr;
      newLabels?: string[];
      newType?: SoQLType;
    }) => void;
    evaluate: (value: Evaluatable) => Promise<void>;
    updateLegacyLabels: (labels: string[]) => void;
    updateInputType: (st: SoQLType) => void;
    deleteField: () => void;
  };

const mapStateToProps = (state: AppState, props: ExternalProps): StateProps => {
  const { template, qualifier, field } = props;
  const compilationResult = option(
    state.templateStates.find((ts) => ts.template.name === props.template.name)
  ).flatMap((ts) => ts.compilation);
  return {
    chan: state.channel,
    scope: state.scope,
    template,
    qualifier,
    field,
    compilationResult,
    deleteField: props.deleteField,
    inputValues: state.inputValues,
    transformResult: state.transformResult
  };
};

function mergeProps(
  stateProps: StateProps,
  { dispatch }: { dispatch: Dispatcher },
  extProps: ExternalProps
): Props {
  const evaluate = (field: FieldT) => (value: Evaluatable) => {
    dispatch({
      type: ActionTypes.FieldInputValuesChanged,
      inputValues: value
    });

    // Ensure we send all the column refs that are required by the
    // expression.
    // Let's say the user has the expr
    //  `foo`
    // and we have the current binding foo = "foo" in the redux state
    // and then they change the expr to be
    //  `foo` || `bar`
    // then we need to ensure that we send `bar` along in the payload,
    // otherwise the interpreter won't have the required bindings to
    // run the expression. So if there are any columnrefs in the expr
    // that we don't have input values for, set them to null explicitly
    // before we do an evaluation.
    const paddedEvaluatable: Evaluatable = typedUniqColumnRefs(stateProps.template, field).map((cref) => {
      const existing = value.find((ev) => isExpressionEqualIgnoringPosition(ev.columnRef, cref));
      return {
        columnRef: cref,
        value: _.isUndefined(existing) ? null : existing.value
      };
    });
    return new Promise<void>((resolve, reject) => {
      stateProps.chan
        .push(
          'evaluate',
          {
            template_name: stateProps.template.name,
            qualifier: stateProps.qualifier,
            field,
            value: paddedEvaluatable
          },
          TemplateChannelTimeout
        )
        .receive('ok', ({ results }: { results: TransformResult[] }) => {
          // we're only ever sending one value to transform, so we
          // only care about the first result that comes back
          const transformResult: TransformResult = results[0];
          dispatch({
            type: ActionTypes.FieldTransformResultChanged,
            transformResult
          });
          resolve();
        })
        .receive('error', reject);
    });
  };

  return {
    ...extProps,
    ...stateProps,
    compileAst: ({ expr, newLabels, newType, clearDefault }: { expr: Expr; newLabels?: string[]; newType?: SoQLType; clearDefault?: boolean }) => {
      const ref = uuid.v4();
      const field = { ...stateProps.field, parsed_expr: expr };
      const qualifier = extProps.qualifier;

      // we clear the default when swapping to select and multiselect
      if (clearDefault) {
        field.default_value = '';
      }

      if (newType) {
        field.input_soql_type = newType;

        dispatch({
          type: ActionTypes.FieldInputTypeChanged,
          templateName: extProps.template.name,
          qualifier,
          newField: field
        });
      }

      if (newLabels) {
        field.legacy_labels = newLabels;
      }
      dispatch(compilationStarted(ref, field, qualifier, stateProps.template));
      debounceCompilation(() => {
        stateProps.chan
          .push(
            'compile_ast',
            {
              ref,
              field,
              qualifier: stateProps.qualifier,
              template_name: stateProps.template.name
            },
            TemplateChannelTimeout
          )
          .receive('ok', compilationOk(field, qualifier, stateProps.template, dispatch, (f: FieldT) => evaluate(f)(stateProps.inputValues)))
          .receive('error', () => dispatch(fatalCompilationError(stateProps.template)))
          .receive('timeout', () => dispatch(fatalCompilationError(stateProps.template)));
      });
    },
    compile: (expr: string) => {
      const ref = uuid.v4();
      if (expr === stateProps.field.expr) return;
      const field = { ...stateProps.field, expr };
      dispatch(compilationStarted(ref, field, stateProps.qualifier, stateProps.template));
      debounceCompilation(() => {
        stateProps.chan
          .push(
            'compile',
            {
              ref,
              field,
              qualifier: stateProps.qualifier,
              template_name: stateProps.template.name
            },
            TemplateChannelTimeout
          )
          .receive('ok', compilationOk(field, stateProps.qualifier, stateProps.template, dispatch, (f: FieldT) => evaluate(f)(stateProps.inputValues)))
          .receive('error', () => dispatch(fatalCompilationError(stateProps.template)))
          .receive('timeout', () => dispatch(fatalCompilationError(stateProps.template)));
      });
    },
    evaluate: evaluate(stateProps.field),
    updateLegacyLabels: (labels: string[]) => {
      dispatch({
        type: ActionTypes.FieldLegacyLabelsChanged,
        templateName: extProps.template.name,
        qualifier: extProps.qualifier,
        field: extProps.field,
        labels
      });
    },
    // todo something like this in field validation
    updateInputType: (newType: SoQLType) => {
      const ref = uuid.v4();
      const newField = { ...stateProps.field, input_soql_type: newType };
      dispatch({
        type: ActionTypes.FieldInputTypeChanged,
        templateName: extProps.template.name,
        qualifier: extProps.qualifier,
        newField
      });
      dispatch(compilationStarted(ref, newField, stateProps.qualifier, stateProps.template));
      stateProps.chan
        .push(
          'compile',
          {
            ref,
            field: newField,
            qualifier: stateProps.qualifier,
            template_name: stateProps.template.name
          },
          TemplateChannelTimeout
        )
        .receive('ok', compilationOk(newField, stateProps.qualifier, stateProps.template, dispatch, (f: FieldT) => evaluate(f)(stateProps.inputValues)))
        .receive('error', () => dispatch(fatalCompilationError(stateProps.template)))
        .receive('timeout', () => dispatch(fatalCompilationError(stateProps.template)));
    }
  };
}

// @ts-ignore-error a null mapDispatchToProps results in passing { dispatch },
// but this case is not covered in the types in Connect.
export default connect(mapStateToProps, null, mergeProps)(FieldEditor);
