import React, { useState, useEffect } from 'react';
import I18n from 'common/i18n';
import FieldWithParentEditor from './FieldWithParentEditor';
import {
  Expr,
  ColumnRef,
  SoQLType,
  FunCall,
  Let,
  Binding,
  SoQLBooleanLiteral
} from 'common/types/soql';
import { FieldT, MetadataType, OptionByParent } from 'common/types/metadataTemplate';
import { buildDefaultMultiSelectWithParent, equalsPredicateBuilder } from 'common/dsmapi/metadataTemplate';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

export interface MultiSelectWithParentEditorProps {
  field: FieldT;
  fieldColumn: ColumnRef;
  parentField: ColumnRef;
  optionsByParent: OptionByParent[];
  parentOptions: string[];
  parentLabels: string[];
  parentDisplayName: string;
  parentType: MetadataType;
  withLabels?: boolean;
  updateExpr: ({
    expr,
    newLabels,
    newType
  }: {
    expr: Expr;
    newLabels?: string[];
    newType?: SoQLType;
  }) => void;
  updateLegacyLabels: (labels: string[]) => void;
  defaultPredicate: SoQLBooleanLiteral;
  staticCases: Expr[];
}

const MultiSelectWithParentEditor: React.FunctionComponent<MultiSelectWithParentEditorProps> = ({
  field,
  fieldColumn,
  parentField,
  optionsByParent,
  parentOptions,
  parentLabels,
  parentDisplayName,
  parentType,
  withLabels,
  updateExpr,
  updateLegacyLabels,
  defaultPredicate,
  staticCases
}) => {
  const [fakeBlockParentValue, setFakeBlockParentValue] = useState(parentOptions[0]);

  useEffect(() => {
    setFakeBlockParentValue(parentOptions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentField.value]);

  const qualifier = fieldColumn.qualifier;

  const makeNewMultiSelectWithParent = (newOptionsByParent: OptionByParent[]): Let => {
    // If it's just the fake option block we put in to make sure there's always one displayed to the user,
    // we don't want that value actually showing up in the expression. Remove it.
    const isOnlyOptionTheFakeBlock =
      newOptionsByParent.length === 1 &&
      newOptionsByParent[0].options.length === 1 &&
      newOptionsByParent[0].options[0] === '' &&
      newOptionsByParent[0].labels[0] === '';

    const validatedNewOptionsByParent: OptionByParent[] = isOnlyOptionTheFakeBlock ? [] : newOptionsByParent;

    const validOptionCases: Array<FunCall> = validatedNewOptionsByParent.map((optionByParent) => {
      const parentCheck: FunCall =
        parentType === MetadataType.select || parentType === MetadataType.dependentSelectWithSelectParent
          ? equalsPredicateBuilder(qualifier, parentField.value, {
              type: 'string_literal',
              value: optionByParent.parentValue
            })
          : {
              type: 'funcall',
              function_name: 'json_array_contains',
              window: null,
              args: [
                parentField,
                {
                  type: 'string_literal',
                  value: optionByParent.parentValue
                }
              ]
            };

      return {
        type: 'funcall',
        function_name: 'case',
        window: null,
        args: [
          parentCheck,
          {
            type: 'funcall',
            function_name: 'cast$json',
            window: null,
            args: [
              {
                type: 'string_literal',
                value: JSON.stringify(optionByParent.options)
              }
            ]
          },
          {
            type: 'boolean_literal',
            value: true
          },
          {
            type: 'funcall',
            function_name: 'cast$json',
            window: null,
            args: [
              {
                type: 'string_literal',
                value: '[]'
              }
            ]
          }
        ]
      };
    });

    // Default empty array to make sure that combine_json_arrays is always given at least one argument
    validOptionCases.push({
      type: 'funcall',
      function_name: 'cast$json',
      window: null,
      args: [
        {
          type: 'string_literal',
          value: '[]'
        }
      ]
    });

    const clauses: Array<Binding> = [
      {
        type: 'binding',
        qualifier: null,
        name: 'valid_options',
        expr: {
          type: 'funcall',
          function_name: 'combine_json_arrays',
          window: null,
          args: validOptionCases
        }
      }
    ];

    // Would be nice if we could just reuse this from the existing function,
    // but we need to retranslate the static part of the error message.

    const newErrorMessage = t('error_messages.multi_select_error_message', {
      name: field.display_name
    });

    const newDefaultConsequent: FunCall = {
      type: 'funcall',
      function_name: 'error',
      window: null,
      args: [
        {
          type: 'funcall',
          function_name: 'op$||',
          window: null,
          args: [
            {
              type: 'string_literal',
              value: newErrorMessage
            },
            {
              type: 'funcall',
              function_name: 'cast$text',
              window: null,
              args: [
                {
                  type: 'funcall',
                  function_name: 'distinct_subtract_arrays',
                  window: null,
                  args: [
                    fieldColumn,
                    {
                      type: 'column_ref',
                      value: 'valid_options',
                      qualifier: null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    };

    const body: FunCall = {
      type: 'funcall',
      function_name: 'case',
      window: null,
      args: [...staticCases, defaultPredicate, newDefaultConsequent]
    };

    const newExpression: Let = {
      type: 'let',
      body,
      clauses
    };

    if (isOnlyOptionTheFakeBlock && newOptionsByParent[0].parentValue !== fakeBlockParentValue) {
      setFakeBlockParentValue(newOptionsByParent[0].parentValue);
    }

    return newExpression;
  };

  let validatedOptionsByParent: OptionByParent[];

  // In order to make sure there's always at least one block of options shown to the user,
  // we'll fake there being one when the optionsByParent array is empty. We don't want this
  // fake option actually in the expression (an empty multiselect option array should truly be [], not [""]),
  // so we add it here and remove it again when building the expression (if it's still there).
  if (optionsByParent.length === 0) {
    validatedOptionsByParent = [
      {
        labels: [''],
        options: [''],
        parentValue: fakeBlockParentValue
      }
    ];
  } else {
    validatedOptionsByParent = optionsByParent;
  }

  return (
    <FieldWithParentEditor
      field={field}
      qualifier={qualifier}
      parentField={parentField}
      parentDisplayName={parentDisplayName}
      parentOptions={parentOptions}
      parentLabels={parentLabels}
      optionsByParent={validatedOptionsByParent}
      withLabels={withLabels}
      updateLegacyLabels={updateLegacyLabels}
      makeNewFieldExpression={makeNewMultiSelectWithParent}
      buildDefaultFieldExpression={buildDefaultMultiSelectWithParent}
      updateExpr={updateExpr}
    />
  );
};

export default MultiSelectWithParentEditor;
