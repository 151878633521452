import { VARIANTS } from 'common/components/Button';
import ConfirmationModal from 'common/components/ConfirmationDialog/ConfirmationModal';
import Modal from 'common/components/Modal';
import { fieldDisplayName } from 'common/dsmapi/metadataTemplate';
import I18n from 'common/i18n';
import { FieldSetT, MetadataTemplate } from 'common/types/metadataTemplate';
import { isColumnRef, TableQualifier, traverseExpr } from 'common/types/soql';
import React, { useState } from 'react';
import { ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

interface Props {
  fieldset: FieldSetT;
  template: MetadataTemplate;
  deleteFieldset: () => void;
}

const FieldDeletionButton: React.FunctionComponent<Props> = ({
  template,
  fieldset,
  deleteFieldset
}) => {
  const [showingConfirmationModal, setShowingConfirmationModal] = useState(false);
  const [showingBrokenRefsModal, setShowingBrokenRefsModal] = useState(false);

  const allFields = template.builtin_fields
    .map((builtin) => ({ qualifier: null as TableQualifier, field: builtin }))
    .concat(
      template.custom_fields.flatMap((fs) =>
        fs.fields.map((customField) => ({ qualifier: fs.fieldset_qualifier, field: customField }))
      )
    )
    .filter(
      (qualifiedField) =>
        // keep only the fields that aren't  *this* field
        qualifiedField.qualifier !== fieldset.fieldset_qualifier
    );

  const brokenRefs = allFields.filter(({ field: otherField }) =>
    // for the remaining fields, keeping the field if it has an expression that
    // references the one we're about to delete anywhere
    traverseExpr(
      otherField.parsed_expr,
      false as boolean,
      (node, acc) => (!!node && isColumnRef(node) && node.qualifier === fieldset.fieldset_qualifier) || acc
    )
  );

  const onClickDelete = () => {
    if (brokenRefs.length > 0) {
      setShowingBrokenRefsModal(true);
    } else {
      setShowingConfirmationModal(true);
    }
  };

  return (
    <>
      <ForgeIconButton data-testid='fieldset-delete-button'>
        <button
          type="button"
          onClick={onClickDelete}
          aria-label={t('delete_fieldset_base')}
        >
          <ForgeIcon className="field-icon" external external-type="extended" name="trash_can_outline" />
        </button>
      </ForgeIconButton>

      {showingBrokenRefsModal && (
        <Modal onDismiss={() => setShowingBrokenRefsModal(false)}>
          <p>{t('delete_conflict')}</p>

          <ul>
            {brokenRefs.map((qualifiedField) => (
              <li key={`${qualifiedField.qualifier}_${qualifiedField.field.field_name}`}>
                {fieldDisplayName(qualifiedField.field)}
              </li>
            ))}
          </ul>

          <p>{t('remove_references_to_fieldset', fieldset)}</p>
        </Modal>
      )}
      {showingConfirmationModal && (
        <ConfirmationModal
          agreeButtonVariant={VARIANTS.ERROR}
          agreeButtonText={t('delete_fieldset_base')}
          onAgree={() => {
            deleteFieldset();
            setShowingConfirmationModal(false);
          }}
          onCancel={() => setShowingConfirmationModal(false)}
          headerText={t('delete_fieldset_title', fieldset)}
          forgeVersion={true}
        >
          {t('delete_fieldset_confirm_body')}
        </ConfirmationModal>
      )}
    </>
  );
};

export default FieldDeletionButton;
