import ConfirmationModal from 'common/components/ConfirmationDialog/ConfirmationModal';
import {
  intoMetadataComponents,
  buildDefaultParentlessSelect,
  buildDefaultMultiSelect
} from 'common/dsmapi/metadataTemplate';
import I18n from 'common/i18n';
import { FieldT, MetadataTemplate, MetadataType } from 'common/types/metadataTemplate';
import { Expr, SoQLType, TableQualifier } from 'common/types/soql';
import React, { useState } from 'react';
import { ForgeOption, ForgeSelect } from '@tylertech/forge-react/';
import TextFieldDefaultValue from './TextFieldDefaultValue';
import { none, Option, some } from 'ts-option';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

interface Props {
  template: MetadataTemplate;
  qualifier: TableQualifier;
  field: FieldT;
  busy: boolean;
  disabled: boolean;
  updateExpr: ({
    expr,
    newLabels,
    newType,
    clearDefault
  }: {
    expr: Expr;
    newLabels?: string[];
    newType?: SoQLType;
    clearDefault?: boolean;
  }) => void;
}

// we don't want to allow people to turn certain "special" fields
// into enumerations. not because it would break anything (it wouldn't)
// but because it will confuse people.
const allowEnumerationification = (field: FieldT): boolean =>
  !field.is_builtin || !['name', 'description', 'tags'].includes(field.field_name);

export const EnumerationificationEditor: React.FunctionComponent<Props> = ({
  busy,
  disabled,
  field,
  qualifier,
  template,
  updateExpr
}) => {
  const [nextFieldTypeForConfirmationDialog, setNextFieldTypeForConfirmationDialog] =
    useState<Option<MetadataType>>(none);
  const [nextInputType, setNextInputType] = useState<SoQLType | undefined>(undefined);

  // this is causing some fields to return an empty card. Asset Description, Asset Name, and Tags
  // this is by design (see above comment) so what do we want to display in the card? It looks
  // very janky.
  if (!allowEnumerationification(field)) return null;

  const makeSelect = (newType?: SoQLType) => {
    updateExpr({
      expr: buildDefaultParentlessSelect(
        field.field_name,
        field.display_name,
        qualifier
      ),
      newType,
      newLabels: [],
      clearDefault: true
    });
    setNextInputType(undefined);
  };

  const makeIdentity = (newType?: SoQLType) => {
    updateExpr({ expr: { type: 'column_ref', value: field.field_name, qualifier }, newType });
    setNextInputType(undefined);
  };

  const makeMultiSelect = (newType?: SoQLType) => {
    updateExpr({
      expr: buildDefaultMultiSelect(field.field_name, qualifier),
      newType,
      newLabels: [],
      clearDefault: true
    });
    setNextInputType(undefined);
  };

  const metadataFieldComponent = intoMetadataComponents(
    qualifier,
    field.field_name,
    field.parsed_expr,
    field.legacy_labels
  );

  const fieldType = metadataFieldComponent.match({
    none: () => MetadataType.text,
    some: ({ type }) => {
      if (type === MetadataType.multiSelect) {
        return MetadataType.multiSelect;
      }

      if (type === MetadataType.dependentMultiSelect) {
        return MetadataType.multiSelect;
      }

      if (
        type === MetadataType.dependentSelectWithSelectParent ||
        type === MetadataType.dependentSelectWithMultiSelectParent
      ) {
        return MetadataType.select;
      }

      return type;
    }
  });

  const isAnySelectOrMultiSelectOptions = metadataFieldComponent.match({
    none: () => false,
    some: ({ options }) => options.some((v) => v) // checks if there are any truthy values in array
  });

  const makeFieldInput = (newFieldType: MetadataType, inputType?: SoQLType) => {
    switch (newFieldType) {
      case MetadataType.text: {
        makeIdentity(inputType);
        break;
      }
      case MetadataType.select: {
        makeSelect(inputType);
        break;
      }
      case MetadataType.multiSelect: {
        makeMultiSelect(inputType);
        break;
      }
    }
  };

  const confirmationModal = (
    <>
      {nextFieldTypeForConfirmationDialog.isDefined && (
        <ConfirmationModal
          forgeVersion={true}
          headerText={t('confirm_field_change')}
          agreeButtonText={t('confirm')}
          onCancel={() => {
            setNextFieldTypeForConfirmationDialog(none);
            setNextInputType(undefined);
          }}
          onAgree={() => {
            makeFieldInput(nextFieldTypeForConfirmationDialog.get, nextInputType);
            setNextFieldTypeForConfirmationDialog(none);
          }}
        >
          {t('delete_field_info')}
        </ConfirmationModal>
      )}
    </>
  );

  return (
    <>
      {confirmationModal}
      <ForgeSelect
        className={'field-editor-select'}
        id="field-editor-field-type-select"
        disabled={disabled}
        onChange={({ target: { value: newFieldType } }: React.ChangeEvent<HTMLInputElement>) => {
          if (newFieldType === fieldType) return;

          let newInputType: SoQLType | undefined;

          if (newFieldType === MetadataType.multiSelect) {
            newInputType = SoQLType.SoQLJsonT;
          } else if (fieldType === MetadataType.multiSelect) {
            newInputType = SoQLType.SoQLTextT;
          }

          if (newInputType) {
            setNextInputType(newInputType);
          }

          const fieldsHaveValues = (() => {
            switch (fieldType) {
              case MetadataType.text: {
                return !!field.default_value;
              }
              case MetadataType.select:
              case MetadataType.multiSelect: {
                return isAnySelectOrMultiSelectOptions;
              }
            }
          })();

          if (fieldsHaveValues) {
            // show the confirmation dialog since there are either a default value or options that would be cleared
            setNextFieldTypeForConfirmationDialog(some(newFieldType as MetadataType));
          } else {
            makeFieldInput(newFieldType as MetadataType, newInputType);
          }
        }}
        data-testid="field-editor-select"
        value={fieldType}
      >
        <h6>
          <label htmlFor="field-editor-field-type-select">{t('field_type')}</label>
        </h6>
        <ForgeOption value={MetadataType.text} selected="selected">
          {t('text_field')}
        </ForgeOption>
        <ForgeOption value={MetadataType.select}>{t('select')}</ForgeOption>
        <ForgeOption value={MetadataType.multiSelect}>{t('multiselect')}</ForgeOption>
      </ForgeSelect>
      {fieldType === MetadataType.text && (
        <TextFieldDefaultValue template={template} qualifier={qualifier} field={field} />
      )}
    </>
  );
};

export default EnumerationificationEditor;
