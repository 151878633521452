import React from 'react';
import I18n from 'common/i18n';
import {
  ForgeScaffold,
  ForgeButtonToggleGroup,
  ForgeButtonToggle,
  ForgeIcon,
  ForgeButton
} from '@tylertech/forge-react';
import { SortOrder, alphabetize, determineAlphabeticalSortOrder, discoverDuplicates, replaceAtIndex } from './helpers';
import EnumerationChoice from './EnumerationChoice';
import { Expr, FunCall, Let, SoQLType } from 'common/types/soql';
import { FieldT } from 'common/types/metadataTemplate';

const t = (translationKey: string, options: { [key: string]: any } = {}) =>
  I18n.t(translationKey, { scope: 'metadata_templates', ...options });

export interface MultiSelectEditorProps {
  field: FieldT;
  updateExpr: ({
    expr,
    newLabels,
    newType
  }: {
    expr: Expr;
    newLabels?: string[];
    newType?: SoQLType;
  }) => void;
  updateLegacyLabels: (labels: string[]) => void;
  makeNewMultiSelect: (newOptions: string[]) => FunCall | Let;
  withLabels?: boolean;
  options: string[];
}

const MultiSelectEditor: React.FunctionComponent<MultiSelectEditorProps> = ({
  field,
  updateExpr,
  updateLegacyLabels,
  makeNewMultiSelect,
  withLabels,
  options
}) => {
  const validatedOptions = options.length === 0 ? [''] : options;
  const showSortToggle = validatedOptions.length > 1;
  const alphabeticalSortOrder = determineAlphabeticalSortOrder(validatedOptions);

  const onAddOption = () => {
    updateExpr({ expr: makeNewMultiSelect([...validatedOptions, '']) });
  };

  const onRemoveOption = (indexToRemove: number) => () => {
    let newLabels;
    const newOptions = [
      ...validatedOptions.slice(0, indexToRemove),
      ...validatedOptions.slice(indexToRemove + 1)
    ];

    if (withLabels) {
      const labels = validatedOptions.map((option, index) => field.legacy_labels[index] || option);
      newLabels = [...labels.slice(0, indexToRemove), ...labels.slice(indexToRemove + 1)];
    }

    updateExpr({ expr: makeNewMultiSelect(newOptions), newLabels });
  };

  const onChangeOption =
    (index: number) =>
    ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
      const newOptions = replaceAtIndex(validatedOptions, value, index);

      updateExpr({ expr: makeNewMultiSelect(newOptions) });
    };

  const onChangeLabel =
    (index: number) =>
    ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
      const labels = validatedOptions.map((option, labelIndex) => {
        if (field.legacy_labels[labelIndex] || field.legacy_labels[labelIndex] === '') {
          return field.legacy_labels[labelIndex];
        }
        return option;
      });

      const newLabels = replaceAtIndex(labels, value, index);

      updateLegacyLabels(newLabels);
    };

  const onAlphabetize = (sortOrder: SortOrder) => {
    const { ordered: orderedOptions, orderedLabels } = alphabetize(
      validatedOptions,
      field.legacy_labels,
      sortOrder === SortOrder.ASCENDING
    );

    updateExpr({ expr: makeNewMultiSelect(orderedOptions), newLabels: orderedLabels });
  };
  const duplicateOptions = discoverDuplicates(validatedOptions);

  const optionsList = validatedOptions.map((choice: string, index: number) => {
    let isDeletable = true;

    // The first option in the list shouldn't be deletable if it's the only one and empty
    if (validatedOptions.length === 1 && choice.length === 0) {
      isDeletable = false;
    }

    return (
      <EnumerationChoice
        key={index}
        withLabels={withLabels || false}
        isDuplicate={duplicateOptions[choice] || false}
        field={field}
        choice={choice}
        onChangeOption={onChangeOption(index)}
        onChangeLabel={onChangeLabel(index)}
        onRemoveOption={onRemoveOption(index)}
        index={index}
        isDeletable={isDeletable}
      />
    );
  });

  return (
    <div className="metadata-editor">
      <ForgeScaffold>
        <div className="metadata-list-header" slot="header">
          <h6>{t('dropdown_options_header')}</h6>
          {showSortToggle && (
            <ForgeButtonToggleGroup dense value={alphabeticalSortOrder}>
              <ForgeButtonToggle
                onClick={() => onAlphabetize(SortOrder.DESCENDING)}
                data-testid="sort-alphabetical-descending-button"
                aria-label={t('sort_options_descending_button_label')}
                value={SortOrder.DESCENDING}
              >
                <ForgeIcon name="sort_alphabetical_descending" />
              </ForgeButtonToggle>
              <ForgeButtonToggle
                onClick={() => onAlphabetize(SortOrder.ASCENDING)}
                data-testid="sort-alphabetical-ascending-button"
                aria-label={t('sort_options_ascending_button_label')}
                value={SortOrder.ASCENDING}
              >
                <ForgeIcon name="sort_alphabetical_ascending" />
              </ForgeButtonToggle>
            </ForgeButtonToggleGroup>
          )}
        </div>
        <div className="metadata-list" slot="body">
          {optionsList}
        </div>
        <div className="metadata-list-footer" slot="footer">
          <ForgeButton>
            <button type="button" onClick={() => onAddOption()} data-testid="add-new-option-button">
              <span>{t('add_option')}</span>
              <ForgeIcon name="add_circle" />
            </button>
          </ForgeButton>
        </div>
      </ForgeScaffold>
    </div>
  );
};

export default MultiSelectEditor;
