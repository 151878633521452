import React from 'react';
import { isUndefined as _isUndefined } from 'lodash';
import { Option, some, none } from 'ts-option';
import I18n from 'common/i18n';
import { ForgeTextField, ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';
import { FieldT } from 'common/types/metadataTemplate';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

interface EnumerationChoiceProps {
  field: FieldT;
  choice: string;
  index: number;
  isDuplicate: boolean;
  blockIndex?: number;
  withLabels: boolean;
  onRemoveOption: () => void;
  onChangeLabel: (e: React.FormEvent<HTMLInputElement>) => void;
  onChangeOption: (e: React.FormEvent<HTMLInputElement>) => void;
  isDeletable: boolean;
}

const EnumerationChoice = ({
  field,
  choice,
  index,
  isDuplicate,
  blockIndex,
  withLabels,
  onRemoveOption,
  onChangeLabel,
  onChangeOption,
  isDeletable
}: EnumerationChoiceProps) => {
  // If this choice is inside of a block, we want to display the index
  // relative to the list inside the block instead of overall

  const displayableIndex = blockIndex === undefined ? index : blockIndex;
  const error: Option<string> = isDuplicate ? some(t('options_must_unique')) : none;
  const labelsSection = withLabels ? (
    <>
      <ForgeTextField invalid={!error.isEmpty} className="metadata-option-textfield" test-dataid={`option-${index}-label-input`}>
        <input
          type="text"
          id={`option-${index}-label-input`}
          placeholder={t('new_option_placeholder')}
          onChange={onChangeLabel}
          value={_isUndefined(field.legacy_labels[index]) ? choice : field.legacy_labels[index]}
        />
        <label htmlFor={`option-${index}-label-input`}>{t('label_n', { n: displayableIndex + 1 })}</label>
      </ForgeTextField>
    </>
  ) : null;

  return (
    <div className="metadata-option">
      <ForgeTextField className="metadata-option-textfield" test-dataid={`option-${index}-text-input`} required={true}>
        <input
          type="text"
          id={`option-${index}-text-input`}
          placeholder={t('new_option_placeholder')}
          onChange={onChangeOption}
          value={choice}
        />
        <label htmlFor={`option-${index}-text-input`}>{t('option_n', { n: displayableIndex + 1 })}</label>
        {!error.isEmpty && <span className="helper-text-for-input-text" data-testid="field-option-error-msg" slot="helper-text">{error.get}</span>}
      </ForgeTextField>
      {labelsSection}
      {isDeletable && (
        <ForgeIconButton>
          <button
            type="button"
            aria-label={t('remove_option_n', { n: displayableIndex + 1 })}
            onClick={onRemoveOption}
          >
            <ForgeIcon name="close" />
          </button>
        </ForgeIconButton>
      )}
    </div>
  );
};

export default EnumerationChoice;
