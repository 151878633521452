import {
  fieldDisplayName,
  isRequiredExpr,
  unwrapRequiredness,
  wrapInRequiredness
} from 'common/dsmapi/metadataTemplate';
import I18n from 'common/i18n';
import { FieldT } from 'common/types/metadataTemplate';
import { ColumnRef, Expr, SoQLType, TableQualifier } from 'common/types/soql';
import React from 'react';
import { ForgeRadio } from '@tylertech/forge-react';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

interface Props {
  qualifier: TableQualifier;
  field: FieldT;
  updateExpr: ({
    expr,
    newLabels,
    newType
  }: {
    expr: Expr;
    newLabels?: string[];
    newType?: SoQLType;
  }) => void;
}

export const RequirednessEditor: React.FunctionComponent<Props> = ({
  field,
  updateExpr,
  qualifier
}) => {
  const expr = field.parsed_expr;

  const fieldColumn: ColumnRef = {
    type: 'column_ref',
    value: field.field_name,
    qualifier
  };

  const makeRequired = () => {
    updateExpr({ expr: wrapInRequiredness(fieldDisplayName(field), field.parsed_expr, fieldColumn) });
  };
  const makeOptional = () => {
    updateExpr({ expr: unwrapRequiredness(field.parsed_expr, fieldColumn) });
  };

  const isRequired = isRequiredExpr(expr, fieldColumn);
  const isSpecialField =
    (field.field_name === 'name' && field.is_builtin) ||
    (field.field_name === 'category' && field.is_builtin);

  if (!isSpecialField) {
    return (
      <div
        role="radiogroup"
        aria-label={t('radio_option_required')}
        className="radio-groups"
        data-testid="field-editor-required-radio"
      >
        <ForgeRadio>
          <input
            type="radio"
            id="radio-required"
            name="requiredRadios"
            value="required"
            onClick={() => makeRequired()}
            onChange={() => { }} // Required to silence a console warning
            checked={isRequired}
            data-testid="field-editor-required-radio-required"
          />
          <label htmlFor="radio-required">{t('required')}</label>
        </ForgeRadio>
        <ForgeRadio>
          <input
            type="radio"
            id="radio-optional"
            name="requiredRadios"
            value="optional"
            onClick={() => makeOptional()}
            onChange={() => { }} // Required to silence a console warning
            checked={!isRequired}
            data-testid="field-editor-required-radio-optional"
          />
          <label htmlFor="radio-optional">{t('optional')}</label>
        </ForgeRadio>
      </div>
    );
  }
  return null;
};

export default RequirednessEditor;
