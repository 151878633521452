import { VARIANTS } from 'common/components/Button';
import I18n from 'common/i18n';
import { isDsmapiBadRequest, PhxChannel } from 'common/types/dsmapi';
import { AddFieldPayload, ChangeTemplatePayload, ChangeTemplateType, FieldSetT, FieldT, MetadataComponentsWithParent, MetadataTemplate, MetadataType, asDependentMetadataType } from 'common/types/metadataTemplate';
import { fieldDisplayName, getField, hasRequiredExprs, intoMetadataComponents, wrapInRequiredness } from 'common/dsmapi/metadataTemplate';
import { ColumnRef, FunSpec, TableQualifier } from 'common/types/soql';
import { ActionTypes, AppState, Dispatcher, TemplateState, compilationOk, compilationStarted, fatalCompilationError } from 'metadataTemplates/store';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { showForgeErrorToastNow, showForgeSuccessToastNow } from 'common/components/ToastNotification/Toastmaster';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { none, Option, option, some } from 'ts-option';
import uuid from 'uuid';
import { escape as _escape, uniq as _uniq, uniqBy as _uniqBy } from 'lodash';
import { AddNewNamedEntity } from './AddNewNamedEntity';
import { BuiltinFieldChooser } from './BuiltinFieldChooser';
import { CustomFieldChooser } from './CustomFieldChooser';
import FieldEditor from './FieldEditor';
import './fieldsets.scss';
import { TemplateChannelTimeout } from 'metadataTemplates/util';
import { FeatureFlags } from 'common/feature_flags';
import * as configApi from 'common/core/configurations';
import {
  ForgeButton,
  ForgeButtonToggle,
  ForgeButtonToggleGroup,
  ForgeCard,
  ForgeCheckbox,
  ForgeExpansionPanel,
  ForgeIcon,
  ForgeOpenIcon,
  ForgePageState,
  ForgeScaffold,
  ForgeToolbar,
  ForgeTooltip,
  ForgeView,
  ForgeViewSwitcher
} from '@tylertech/forge-react';
import ConfirmationModal from 'common/components/ConfirmationDialog/ConfirmationModal';
import DragDropContainer, { DragDropContainerType, DragDropElementWrapper } from 'common/components/DragDropContainer';
import _ from 'lodash';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

const InheritCustomMetadataConfig = 'tabular_assets_inherit_custom_metadata';

interface ChildAndParentField {
  qualifier: TableQualifier,
  child: FieldT
  parent: FieldT
}

const SelectAField = () => (
  <div className="field-editor select-a-field">
    <p>{t('select_a_field')}</p>
  </div>
);

const selectAFieldForged = (createFieldSetOnClick: () => void) => (
  <ForgePageState>
    <img
      src="https://cdn.forge.tylertech.com/v1/images/spot-hero/student-activities-setup-spot-hero-desktop.svg"
      slot="graphic"
      alt=""
    />
    <p slot="message">{t('select_a_field')}</p>
    <ForgeButton type="raised" slot="action">
      <button onClick={createFieldSetOnClick} data-testid="create-field-sets-button-from-page-state">
        {t('create_fieldset')}
      </button>
    </ForgeButton>
  </ForgePageState>
);

export const FieldSets: React.FunctionComponent<Props> = (props) => {
  // TODO: multi-template work will change this
  const state = props.templateStates[0];

  const template = option(state ? state.template : null);

  // we just want to keep the ref here, because templates will change when props change
  const [editingFieldName, setEditingField] =
    useState<Option<{ name: string; qualifier: string | null }>>(none);
  const [fieldViewIndex, setFieldViewIndex] = useState<number>(0);
  const [showAddFieldsetDialog, setShowAddFieldsetDialog] = useState(false);
  const [metadataInheritanceConfig, setMetadataInheritanceConfig] = useState<any>(null);
  useEffect(() => {
    // this is a stupid extra wrapping because useEffect wants a cleanup fun.
    const doit = async () => {
      const config = await configApi.fetchInheritMetadataConfig();
      setMetadataInheritanceConfig(config);
    };
    doit();
  }, []);

  const field = template.flatMap((tpl) =>
    editingFieldName.flatMap((e) => getField(tpl, e.name, e.qualifier))
  );
  const fieldset = template.flatMap((tpl) =>
    editingFieldName.flatMap((e) =>
      option(tpl.custom_fields.find((fs) => fs.fieldset_qualifier === e.qualifier))
    )
  );

  const selectsWithNoOptions: string[] = [];
  const selectsWithDuplicateOptions: string[] = [];

  template.forEach(currentTemplate => {
    currentTemplate.custom_fields.forEach((cf): any => {
      const qualifier = cf.fieldset_qualifier;
      cf.fields.forEach((f) => {
        intoMetadataComponents(
          qualifier,
          f.field_name,
          f.parsed_expr,
          f.legacy_labels
        ).forEach(metadataFieldComponent => {
          const { options } = metadataFieldComponent;
          // for some reason, I keep seeing select statements with an options array of ['']
          if (options.filter((o: string) => o != '').length == 0) {
            selectsWithNoOptions.push(f.field_name);
          }

          asDependentMetadataType(metadataFieldComponent).match({
            some: (d) => {
              // you can have an option that appears under different parent values
              // but not that appears multiple times under the same parent value
              d.optionsByParent.forEach(obp => {
                if (_uniq(obp.options).length != obp.options.length) {
                  selectsWithDuplicateOptions.push(f.field_name);
                }
              });
            },
            none: () => {
              if (_uniq(options).length != options.length) {
                selectsWithDuplicateOptions.push(f.field_name);
              }
            }
          });
        });
      });
    });
  });

  const saveable = state && state.saveable && selectsWithNoOptions.length == 0 && selectsWithDuplicateOptions.length == 0;

  const getSaveButtonMessage = () => {
    if (selectsWithNoOptions.length > 0) {
      return <span className="metadata-unsaved-changes">{t('options_must_have_value', { field_names: selectsWithNoOptions.join(', ') })}</span>;
    } else if (selectsWithDuplicateOptions.length > 0) {
      return (
        <span className="metadata-unsaved-changes">
          {t('options_cannot_be_repeated', { field_names: selectsWithDuplicateOptions.join(', ') })}
        </span>
      );
    } else if (saveable) {
      return <span className="metadata-unsaved-changes">{t('unsaved_changes')}</span>;
    } else {
      return null;
    }
  };

  const setFocus = (newTemplate: MetadataTemplate, payload: AddFieldPayload) => {
    // focus on the field that is being changed in newTemplate from the old payload.template_state
    const index = newTemplate.custom_fields.findIndex(f => f.fieldset_name === payload.fieldset_name);
    const fieldsFoundOnNewTemplateFromDsmapi = newTemplate.custom_fields[index].fields;
    const fieldsFoundOnPayloadChangedByUser = payload.template_state.custom_fields[index].fields;
    const newField = (fieldsFoundOnNewTemplateFromDsmapi.filter(fieldFromDsmapi =>
      !fieldsFoundOnPayloadChangedByUser.some((fieldFromUsersChange: FieldT) =>
        JSON.stringify(fieldFromUsersChange) === JSON.stringify(fieldFromDsmapi))));
    setEditingField(some({ name: newField[0].field_name, qualifier: newTemplate.custom_fields[index].fieldset_qualifier }));
  };

  const onAddFieldset = (tpl: MetadataTemplate) => (fieldsetName: string) => {
    props.addFieldset(tpl, fieldsetName);
  };

  const onDeleteFieldset = (tpl: MetadataTemplate) => (fs: FieldSetT) => {
    props.deleteFieldset(tpl, fs);
  };

  const onAddField = (tpl: MetadataTemplate) => (fs: FieldSetT, displayName: string) => {
    props.addField(tpl, fs, displayName, setFocus);
  };

  const onDeleteField = (tpl: MetadataTemplate, fs: FieldSetT, toDelete: FieldT) => {
    props.deleteField(tpl, fs, toDelete);
  };

  const validateFieldsetName = (tpl: MetadataTemplate) => (proposedName: string) =>
    option(tpl.custom_fields.find((fs) => fs.fieldset_name === proposedName)).map(() =>
      t('fieldset_name_taken')
    );

  interface MetadataComponentAndField {
    field: FieldT,
    component: MetadataComponentsWithParent
  }

  const discoverBadChildParentFields = (fs: FieldSetT): ChildAndParentField[] => {
    const requiredChildren: MetadataComponentAndField[] = fs.fields.flatMap(f => {
      if (hasRequiredExprs([f], fs.fieldset_qualifier)) {
        return intoMetadataComponents(
          fs.fieldset_qualifier,
          f.field_name,
          f.parsed_expr,
          f.legacy_labels
        ).match({
          none: () => [],
          some: (metadataComponent) =>
            asDependentMetadataType(metadataComponent).match({
              some: (metadataWithParent) => [{ field: f, component: metadataWithParent }],
              none: () => []
            })
        });
      }
      return [];
    });
    return requiredChildren.flatMap((child) => {
      const parent = fs.fields.find(f => f.field_name === child.component.parentField.value);
      if (parent && !hasRequiredExprs([parent], fs.fieldset_qualifier)) {
        return [{
          qualifier: child.component.inputCref.qualifier,
          child: child.field,
          parent: parent
        }];
      }
      return [];
    });
  };

  const findRequiredChildrenWithOptionalParents = (tpl: MetadataTemplate) => {
    return tpl.custom_fields.flatMap((fs) => discoverBadChildParentFields(fs));
  };

  const [isSaving, setIsSaving] = useState(false);
  const [badChildren, setBadChildren] = useState([] as ChildAndParentField[]);
  const [saveButtonVariant, setSaveButtonVariant] = useState(VARIANTS.PRIMARY);
  const onSave = async (tpl: MetadataTemplate) => {
    const discoveredBadChildren = findRequiredChildrenWithOptionalParents(tpl);
    if (discoveredBadChildren.length > 0) {
      setBadChildren(discoveredBadChildren);
      return;
    }
    try {
      setIsSaving(true);
      await props.saveTemplate(tpl);
      // check for required child with optional parent
      // if any exist, show warning dialog
      setSaveButtonVariant(VARIANTS.SUCCESS);
      setTimeout(() => {
        setSaveButtonVariant(VARIANTS.PRIMARY);
      }, 3000);
      showForgeSuccessToastNow(t('save_success'));
    } catch (e) {
      const errorMessage = isDsmapiBadRequest(e) ? e.english : t('unknown_error');
      showForgeErrorToastNow(errorMessage);
      // this shouldn't happen. there shouldn't be a path where we are incrementally
      // modifying the template and then it blows up on save. anything here is an internal
      // error, and there's nothing the user can do
      console.error(e);
      setSaveButtonVariant(VARIANTS.ERROR);
    }
    setIsSaving(false);
  };

  const badChildrenConfirmationDialog = (tpl: MetadataTemplate) => {
    if (badChildren.length === 0) return null;
    const uniqParents = _uniq(badChildren.map(c => c.parent.display_name));
    const childFields = _escape(badChildren.map(c => c.child.display_name).join(', '));
    const parentFields = _escape(uniqParents.join(', '));
    const oneOrOther = uniqParents.length === 1 ? 'one' : 'other';

    return (
      <ConfirmationModal
        forgeVersion={true}
        headerText={t(`invalid_child_permissions.header.${oneOrOther}`)}
        agreeButtonText={t(`invalid_child_permissions.agree.${oneOrOther}`)}
        onCancel={() => setBadChildren([])}
        onAgree={() => {
          props.setParentFieldsRequired(badChildren, tpl, onSave);
          setBadChildren([]);
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: t(`invalid_child_permissions.body.${oneOrOther}`, { childFields, parentFields }) }} />
      </ConfirmationModal>
    );
  };


  const isSelected = (qualifier: TableQualifier) => (f: FieldT) =>
    editingFieldName
      .map((editing) => editing.name === f.field_name && editing.qualifier === qualifier)
      .getOrElseValue(false);

  const handleToggleChange = (value: number) => {
    if (value) setFieldViewIndex(value);
  };

  const onCreateFieldSet = () => {
    if (fieldViewIndex == 0) setFieldViewIndex(1);
    setShowAddFieldsetDialog(true);
  };

  const setMetadataInheritance = async (value: boolean) => {
    if (metadataInheritanceConfig) {
      // inherit metadata config is already created (most common case)
      try {
        const updatedProperty = await configApi.updateConfigProperty(metadataInheritanceConfig.id, InheritCustomMetadataConfig, value);
        setMetadataInheritanceConfig({ ...metadataInheritanceConfig, properties: [updatedProperty] });
        displayInheritanceToast(value);
      } catch (error) {
        showForgeErrorToastNow(t('inherit_metadata.error'));
      }
    } else {
      // the config appears to be not created, this should only happen the first time someone on that domain interacts with this.
      // however there is also a chance that the api call to configApi.fetchInheritMetadataConfig() could have failed which we could erroneously interpret as there being no config.
      // fetch the config again and make sure the request does not fail before creating the config... we really don't want to end up with more that one config for metadata inheritance.
      const config = {
        name: 'Metadata Inheritance',
        type: 'metadata_inheritance',
        default: true,
      };

      try {
        const configShouldBeNull = await configApi.fetchInheritMetadataConfig();
        if (configShouldBeNull) {
          // we should have fetched the config on page load, if wasn't but shows up here, something went wrong.
          showForgeErrorToastNow(t('inherit_metadata.error'));
          return;
        }
        const newConfig = await configApi.createConfig(config);

        // now you have to add the properties cause you cannot do it at the same time
        const newConfigProps = await configApi.addConfigProperty(newConfig.id, InheritCustomMetadataConfig);
        setMetadataInheritanceConfig({ ...newConfig, properties: [newConfigProps] });
        displayInheritanceToast(value);
      } catch (error) {
        showForgeErrorToastNow(t('inherit_metadata.error'));
      }
    }
  };

  const displayInheritanceToast = (enrolled: boolean) => {
    if (enrolled) {
      showForgeSuccessToastNow(t('inherit_metadata.enrolled'));
    } else {
      showForgeSuccessToastNow(t('inherit_metadata.unenrolled'));
    }
  };

  const shouldShowInheritMetadata = fieldViewIndex == 1 && FeatureFlags.value('enable_derived_view_metadata_inheritance');

  const customFieldChooserComponent = (fs: FieldSetT, metadataTemplate: MetadataTemplate) => {
    return (
      <div key={fs.fieldset_qualifier}>
        <ForgeExpansionPanel data-testid="fieldsets-expansion-panel">
          <div className="fieldset-group-header" slot="header">
            <h1 className="forge-typography--title forge-header-title">
              {fs.fieldset_name}
            </h1>
            <ForgeOpenIcon />
          </div>
          <div id="expansion-panel-content" role="group">
            <CustomFieldChooser
              busy={isSaving}
              template={metadataTemplate}
              onAddField={onAddField(metadataTemplate)}
              onDeleteFieldset={onDeleteFieldset(metadataTemplate)}
              fieldset={fs}
              isSelected={isSelected(fs.fieldset_qualifier)}
              onReorderFields={props.reorderFields}
              onChooseField={(f) =>
                setEditingField(
                  some({ name: f.field_name, qualifier: fs.fieldset_qualifier })
                )
              }
            />
          </div>
        </ForgeExpansionPanel>
      </div>
    );
  };

  const customFieldSetSection = (metadataTemplate: MetadataTemplate) => {
    const customFieldDnd: DragDropElementWrapper[] = metadataTemplate.custom_fields.map((customField) => (
      {
        dragAndDroppable: true,
        element: customFieldChooserComponent(customField, metadataTemplate),
        className: 'fieldset-card'
      }
    ));

    const onChangeOrder = (newItems: any) => {
      props.reorderFieldSets(metadataTemplate, newItems);
    };

    return (
      <div data-testid="custom-field-chooser-view">
        <DragDropContainer
          type={DragDropContainerType.FORGE_CARD}
          childElements={customFieldDnd}
          items={metadataTemplate.custom_fields}
          onDrop={onChangeOrder}
        />
      </div>
    );
  };

  const inheritParentMetadataSection = () => {
    if (!shouldShowInheritMetadata) return null;

    const handleOnParentMetadataClick = (value: any) => setMetadataInheritance(value);

    const checkboxAttributes = {
      'data-testid': 'set-metadata-inheritance-checkbox',
      checked: !!metadataInheritanceConfig?.properties[0]?.value, // could be null if the config is not set
      onClick: (event: any) => handleOnParentMetadataClick(event.target.checked),
      onChange: () => { } // needed otherwise you get a console error
    };

    return (<div className='inherit-metadata-section'>
      <ForgeCheckbox>
        <input type="checkbox" {...checkboxAttributes} />
        <label className='label-section'>
          {t('inherit_metadata.label')}
          <ForgeIcon slot="trailing" name="info" className="field-icon">
            <ForgeTooltip position="top" delay={100}>
              {t('inherit_metadata.tool_tip')}
            </ForgeTooltip>
          </ForgeIcon>
        </label>
      </ForgeCheckbox>
    </div>);
  };

  return (
    <div className="fieldset-builder">
      {template
        .map((tpl) => (
          <ForgeScaffold>
            <div slot="body-left" className="fieldset-left-bar">
              <div className="fieldset-left-body">
                <ForgeButtonToggleGroup
                  className="fieldset-button-group"
                  value={fieldViewIndex || 0}
                  data-testid="fieldset-button-group"
                  on-forge-button-toggle-group-change={(event: CustomEvent) =>
                    handleToggleChange(event.detail)
                  }
                >
                  <ForgeButtonToggle
                    className="fieldset-button-toggle"
                    value={0}
                    data-testid="fieldset-option-default"
                  >
                    {t('default')}
                  </ForgeButtonToggle>
                  <ForgeButtonToggle
                    className="fieldset-button-toggle"
                    value={1}
                    data-testid="fieldset-option-custom"
                  >
                    {t('custom')}
                  </ForgeButtonToggle>
                </ForgeButtonToggleGroup>
                <div>
                  <ForgeViewSwitcher index={fieldViewIndex}>
                    <ForgeView data-testid="builtin-field-chooser-view">
                      <BuiltinFieldChooser
                        template={tpl}
                        isSelected={isSelected(null)}
                        onChooseField={(f) =>
                          setEditingField(some({ name: f.field_name, qualifier: null }))
                        }
                      />
                    </ForgeView>
                    {customFieldSetSection(tpl)}
                  </ForgeViewSwitcher>
                </div>

                <div className="add-fieldset">
                  <AddNewNamedEntity
                    onHide={() => setShowAddFieldsetDialog(false)}
                    buttonLabel={t('fieldset_name_body')}
                    busy={isSaving}
                    placeholder={t('fieldset_name')}
                    validateName={validateFieldsetName(tpl)}
                    onAdd={onAddFieldset(tpl)}
                    label={t('new_fieldset_alt')}
                    dialogOpen={showAddFieldsetDialog}
                  />
                </div>
              </div>
              {inheritParentMetadataSection()}
            </div>

            <div slot="body">
              {badChildrenConfirmationDialog(tpl)}
              {field
                .map((f) => (
                  <FieldEditor
                    deleteField={() => fieldset.forEach((fs) => onDeleteField(tpl, fs, f))}
                    template={tpl}
                    qualifier={editingFieldName.map((editing) => editing.qualifier).getOrElseValue(null)}
                    field={f}
                  />
                ))
                .getOrElseValue(<>{selectAFieldForged(() => onCreateFieldSet())}</>)}
            </div>

            <div slot="body-footer">
              <ForgeToolbar inverted={true}>
                <div slot="end">
                  {getSaveButtonMessage()}
                  <ForgeButton type="raised" className="metadata-lower-nav-button">
                    <button
                      onClick={() => onSave(tpl)}
                      disabled={!saveable}
                      data-testid="save-metadata-button"
                    >
                      {t('save')}
                    </button>
                  </ForgeButton>
                  <ForgeButton type="outlined" className="metadata-lower-nav-button">
                    <button
                      onClick={() => onCreateFieldSet()}
                      data-testid="create-fieldset-button"
                    >
                      {t('create_fieldset')}
                    </button>
                  </ForgeButton>
                </div>
              </ForgeToolbar>
            </div>
          </ForgeScaffold>
        ))
        .getOrElseValue(<span></span>)}
    </div>
  );
};

const blockNavigation = () => {
  window.onbeforeunload = (e: Event) => {
    e.preventDefault();
    e.returnValue = true;
    return '';
  };
};

const unblockNavigation = () => {
  window.onbeforeunload = null;
};

interface ExternalProps { }
interface StateProps {
  templateStates: TemplateState[];
  scope: FunSpec[];
  chan: PhxChannel;
}
interface DispatchProps {
  saveTemplate: (template: MetadataTemplate) => Promise<MetadataTemplate>;
  reorderFields: (template: MetadataTemplate, fieldset: FieldSetT) => void;
  reorderFieldSets: (template: MetadataTemplate, fieldSets: FieldSetT[]) => void;
  addFieldset: (template: MetadataTemplate, fieldsetName: string) => void;
  deleteFieldset: (template: MetadataTemplate, fieldset: FieldSetT) => void;
  addField: (template: MetadataTemplate, fieldset: FieldSetT, displayName: string, setFocus: (newTemplate: MetadataTemplate, payload: any) => void) => void;
  deleteField: (template: MetadataTemplate, fieldset: FieldSetT, field: FieldT) => void;
  setParentFieldsRequired: (badChildren: ChildAndParentField[], template: MetadataTemplate, handleSuccess: (tmp: MetadataTemplate) => void) => void;
}
export type Props = StateProps & DispatchProps;

const mapStateToProps = (state: AppState, props: ExternalProps): StateProps => {
  return {
    templateStates: state.templateStates,
    scope: state.scope,
    chan: state.channel
  };
};

const mergeProps = (
  stateProps: StateProps,
  { dispatch }: { dispatch: Dispatcher },
  extProps: ExternalProps
): Props => {
  const changeTemplate = (event: string, payload: ChangeTemplatePayload, setFocus?: (newTemplate: MetadataTemplate, payload: AddFieldPayload) => void) => {
    stateProps.chan
      .push(event, payload, TemplateChannelTimeout)
      .receive('ok', (newTemplate: MetadataTemplate) => {
        blockNavigation();
        if (setFocus && payload.type === ChangeTemplateType.addField) setFocus(newTemplate, payload);
        dispatch({
          type: ActionTypes.TemplateChange,
          saveable: true,
          template: newTemplate
        });
      })
      .receive('error', console.error);
  };

  return {
    ...stateProps,
    ...extProps,
    saveTemplate: (template: MetadataTemplate): Promise<MetadataTemplate> => {
      return new Promise((resolve, reject) => {
        stateProps.chan
          .push('save', template, TemplateChannelTimeout)
          .receive('ok', (newTemplate) => {
            unblockNavigation();
            dispatch({
              type: ActionTypes.TemplateChange,
              saveable: false,
              template: newTemplate
            });
            resolve(newTemplate);
          })
          .receive('error', reject);
      });
    },
    reorderFields: (template: MetadataTemplate, newFieldset: FieldSetT): void => {
      const newCustomFields = template.custom_fields.map(fs => {
        if (fs.fieldset_qualifier == newFieldset.fieldset_qualifier) {
          return newFieldset;
        }
        return fs;
      });
      dispatch({
        type: ActionTypes.TemplateChange,
        saveable: true,
        template: {
          ...template,
          custom_fields: newCustomFields
        }
      });
    },
    reorderFieldSets: (template: MetadataTemplate, newCustomFieldSets: FieldSetT[]): void => {
      dispatch({
        type: ActionTypes.TemplateChange,
        saveable: true,
        template: {
          ...template,
          custom_fields: newCustomFieldSets
        }
      });
    },
    addFieldset: (template: MetadataTemplate, fieldsetName: string): void => {
      changeTemplate('add_fieldset', {
        template_name: template.name,
        fieldset_name: fieldsetName,
        template_state: template,
        type: ChangeTemplateType.base
      });
    },
    addField: (template: MetadataTemplate, fieldset: FieldSetT, displayName: string, setFocus: (newTemplate: MetadataTemplate, payload: AddFieldPayload) => void): void => {
      changeTemplate('add_field', {
        template_name: template.name,
        fieldset_name: fieldset.fieldset_name,
        display_name: displayName,
        template_state: template,
        type: ChangeTemplateType.addField
      }, setFocus);
    },
    deleteFieldset: (template: MetadataTemplate, fieldset: FieldSetT) => {
      changeTemplate('delete_fieldset', {
        template_name: template.name,
        fieldset_name: fieldset.fieldset_name,
        template_state: template,
        type: ChangeTemplateType.base
      });
    },
    deleteField: (template: MetadataTemplate, fieldset: FieldSetT, field: FieldT): void => {
      changeTemplate('delete_field', {
        template_name: template.name,
        fieldset_name: fieldset.fieldset_name,
        field_name: field.field_name,
        template_state: template,
        type: ChangeTemplateType.deleteField
      });
    },
    setParentFieldsRequired: (badChildren: ChildAndParentField[], template: MetadataTemplate, doSave: (tmp: MetadataTemplate) => void): void => {
      // these must all share a ref so that the reducer for FieldCompilationSucceeded updates for each of them.
      const ref = uuid.v4();
      const uniqParents = _uniqBy(badChildren, 'parent');
      uniqParents.forEach((childAndParent, index) => {
        const field = childAndParent.parent;
        const qualifier = childAndParent.qualifier;
        const parentColRef: ColumnRef = {
          type: 'column_ref',
          value: field.field_name,
          qualifier
        };
        const expr = wrapInRequiredness(fieldDisplayName(field), field.parsed_expr, parentColRef);
        dispatch(compilationStarted(ref, field, qualifier, template));

        const handleSuccess = index === (badChildren.length - 1) ? () => dispatch({
          type: ActionTypes.TriggerAutoSave,
          templateName: template.name,
          doSave
        }) : undefined;

        stateProps.chan
          .push(
            'compile_ast',
            {
              ref,
              field: { ...field, parsed_expr: expr },
              qualifier,
              template_name: template.name
            },
            TemplateChannelTimeout
          )
          .receive('ok', compilationOk(field, qualifier, template, dispatch, handleSuccess))
          .receive('error', () => dispatch(fatalCompilationError(template)))
          .receive('timeout', () => dispatch(fatalCompilationError(template)));
      });
    }
  };
};

// @ts-ignore-error a null mapDispatchToProps results in passing { dispatch },
// but this case is not covered in the types in Connect.
// eslint-disable-next-line new-cap
export default connect(mapStateToProps, null, mergeProps)(DragDropContext(HTML5Backend)(FieldSets));
