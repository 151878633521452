import {
  CompilationStatus,
  isCompilationStarted,
  SimpleCompilationFailed,
  SimpleCompilationStarted,
  SimpleCompilationSucceeded
} from 'common/types/compiler';
import { PhxChannel, TransformResult } from 'common/types/dsmapi';
import { FieldT, MetadataTemplate } from 'common/types/metadataTemplate';
import { FunSpec, TableQualifier } from 'common/types/soql';
import { Store, MiddlewareAPI, Dispatch, applyMiddleware, compose, createStore } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Evaluatable } from './components/FieldEditor';
import { none, Option, some } from 'ts-option';

export type FieldCompilationStarted = SimpleCompilationStarted & {
  ref: string;
  qualifier: TableQualifier;
  field_name: string;
};

export type FieldCompilationSucceeded = SimpleCompilationSucceeded & {
  qualifier: TableQualifier;
  field_name: string;
  field: FieldT;
  ref: string;
};

export type FieldCompilationFailed = SimpleCompilationFailed & {
  qualifier: TableQualifier;
  field_name: string;
  ref: string;
};

export type FieldCompilation = FieldCompilationStarted | FieldCompilationSucceeded | FieldCompilationFailed;

export interface TemplateState {
  template: MetadataTemplate;
  compilation: Option<FieldCompilation>;
  saveable: boolean;
}

export interface AppState {
  templateStates: TemplateState[];
  scope: FunSpec[];
  channel: PhxChannel;
  connected: boolean;
  inputValues: Evaluatable;
  transformResult: TransformResult | null;
}

export enum ActionTypes {
  ChannelConnected = 'ChannelConnected',
  ChannelDisconnected = 'ChannelDisconnected',
  TemplatesChange = 'TemplatesChange',
  TemplateChange = 'TemplateChange',
  FieldRestrictedChanged = 'FieldRestrictedChanged',
  FieldVisibilityChanged = 'FieldVisibilityChanged',
  FieldLegacyLabelsChanged = 'FieldLegacyLabelsChanged',
  FieldInputTypeChanged = 'FieldInputTypeChanged',
  FieldInputValuesChanged = 'FieldInputValuesChanged',
  FieldTransformResultChanged = 'FieldTransformResultChanged',
  FieldCompilationStarted = 'FieldCompilationStarted',
  FieldCompilationSucceeded = 'FieldCompilationSucceeded',
  FieldCompilationFailed = 'FieldCompilationFailed',
  FieldCompilationFatalError = 'FieldCompilationFatalError',
  ScopeChange = 'ScopeChange',
  UpdateFieldDefaultValue = 'UpdateFieldDefaultValue',
  TriggerAutoSave = 'TriggerAutoSave'
}
interface TemplatesChange {
  type: ActionTypes.TemplatesChange;
  templates: MetadataTemplate[];
}
interface TemplateChange {
  type: ActionTypes.TemplateChange;
  saveable: boolean;
  template: MetadataTemplate;
}
interface FieldRestrictedChanged {
  type: ActionTypes.FieldRestrictedChanged;
  templateName: string;
  qualifier: TableQualifier;
  field: FieldT;
  isRestricted: boolean;
}

interface FieldVisibilityChanged {
  type: ActionTypes.FieldVisibilityChanged;
  templateName: string;
  qualifier: TableQualifier;
  field: FieldT;
  isPrivate: boolean;
}
interface FieldLegacyLabelsChanged {
  type: ActionTypes.FieldLegacyLabelsChanged;
  templateName: string;
  qualifier: TableQualifier;
  field: FieldT;
  labels: string[];
}
interface FieldInputTypeChanged {
  type: ActionTypes.FieldInputTypeChanged;
  templateName: string;
  qualifier: TableQualifier;
  newField: FieldT;
}
interface FieldInputValuesChanged {
  type: ActionTypes.FieldInputValuesChanged;
  inputValues: Evaluatable;
}
interface FieldTransformResultChanged {
  type: ActionTypes.FieldTransformResultChanged;
  transformResult: TransformResult;
}
interface CompileFieldStarted {
  type: ActionTypes.FieldCompilationStarted;
  ref: string;
  field: FieldT;
  qualifier: TableQualifier;
  templateName: string;
}
interface CompileFieldSucceeded {
  type: ActionTypes.FieldCompilationSucceeded;
  templateName: string;
  result: FieldCompilationSucceeded;
}
interface CompileFieldFailed {
  type: ActionTypes.FieldCompilationFailed;
  templateName: string;
  result: FieldCompilationFailed;
  qualifier: TableQualifier;
  field: FieldT;
}
interface CompileFieldFatalError {
  type: ActionTypes.FieldCompilationFatalError;
  templateName: string;
}

interface ScopeChange {
  type: ActionTypes.ScopeChange;
  scope: FunSpec[];
}
interface ChannelConnected {
  type: ActionTypes.ChannelConnected;
}
interface ChannelDisconnected {
  type: ActionTypes.ChannelDisconnected;
}
interface UpdateFieldDefaultValue {
  type: ActionTypes.UpdateFieldDefaultValue;
  templateName: string;
  qualifier: TableQualifier;
  field: FieldT;
  defaultValue: string;
}

interface TriggerAutoSave {
  type: ActionTypes.TriggerAutoSave;
  templateName: string;
  doSave: (tmp: MetadataTemplate) => void;
}
export type Actions =
  | ChannelConnected
  | ChannelDisconnected
  | TemplatesChange
  | TemplateChange
  | FieldRestrictedChanged
  | FieldVisibilityChanged
  | FieldLegacyLabelsChanged
  | FieldInputTypeChanged
  | FieldInputValuesChanged
  | FieldTransformResultChanged
  | CompileFieldStarted
  | CompileFieldSucceeded
  | CompileFieldFailed
  | CompileFieldFatalError
  | ScopeChange
  | UpdateFieldDefaultValue
  | TriggerAutoSave;

export type Dispatcher = ThunkDispatch<AppState, void, Actions>;

// methods about dispatching compilation events, shared between FieldEditor and FieldSet
export const compilationStarted = (ref: string, field: FieldT, qualifier: TableQualifier, template: MetadataTemplate): CompileFieldStarted => (
  {
    type: ActionTypes.FieldCompilationStarted,
    ref,
    field,
    qualifier,
    templateName: template.name
  }
);

export const compilationOk = (
  field: FieldT,
  qualifier: TableQualifier,
  template: MetadataTemplate,
  dispatch: Dispatcher,
  handleSuccess?: (field: FieldT) => void) => (result: FieldCompilationSucceeded | FieldCompilationFailed) => {
  if (result.type === CompilationStatus.Succeeded) {
    dispatch({
      type: ActionTypes.FieldCompilationSucceeded,
      templateName: template.name,
      result
    });
    if (handleSuccess) handleSuccess(result.field);
  } else if (result.type === CompilationStatus.Failed) {
    dispatch({
      type: ActionTypes.FieldCompilationFailed,
      templateName: template.name,
      qualifier,
      result,
      field
    });
  }
};

export const fatalCompilationError = (template: MetadataTemplate): CompileFieldFatalError => (
  {
    type: ActionTypes.FieldCompilationFatalError,
    templateName: template.name
  }
);

const putField = (template: MetadataTemplate, qualifier: TableQualifier, field: FieldT): MetadataTemplate => {
  const replaceField = (fields: FieldT[]): FieldT[] =>
    fields.map((b) => (b.field_name === field.field_name ? field : b));
  // whenever compilation starts, we're modifying a field. we could be either modifying
  // the expr string or the ast
  return field.is_builtin
    ? { ...template, builtin_fields: replaceField(template.builtin_fields) }
    : {
        ...template,
        custom_fields: template.custom_fields.map((fieldset) =>
          fieldset.fieldset_qualifier === qualifier
            ? { ...fieldset, fields: replaceField(fieldset.fields) }
            : fieldset
        )
      };
};

const putFieldUpdate = (template: MetadataTemplate, qualifier: TableQualifier, fieldname: string, isBuiltin: boolean, updates: Partial<FieldT>): MetadataTemplate => {
  const updateField = (fields: FieldT[]): FieldT[] =>
    fields.map((b) => b.field_name === fieldname ? {...b, ...updates} : b);

  return isBuiltin
    ? { ...template, builtin_fields: updateField(template.builtin_fields) }
    : {
        ...template,
        custom_fields: template.custom_fields.map((fieldset) =>
          fieldset.fieldset_qualifier === qualifier
            ? { ...fieldset, fields: updateField(fieldset.fields) }
            : fieldset
        )
      };
};

const compilationRefHasChanged = (
  compilation: Option<FieldCompilation>,
  result: FieldCompilationFailed | FieldCompilationSucceeded
) => {
  return compilation
    .map((comp) => {
      if (isCompilationStarted<FieldCompilationStarted>(comp)) {
        return comp.ref !== result.ref;
      }
      return false;
    })
    .getOrElseValue(false);
};

const putTemplate = (
  states: TemplateState[],
  name: string,
  updater: (oldState: TemplateState) => TemplateState
) => states.map((state) => (state.template.name === name ? { ...state, ...updater(state) } : state));


const init: (chan: PhxChannel) => Store<AppState> = (chan: PhxChannel) => {
  const initialState = {
    templateStates: [],
    inputValues: [],
    transformResult: null,
    scope: [],
    channel: chan,
    connected: false
  };

  const reducer = (st: AppState | undefined, action: Actions): AppState => {
    const state = st || initialState;
    // yep, i don't want to use the redux logger because it's annoying
    // console.log('action', action, state);
    const doit = (): AppState => {
      switch (action.type) {
        case ActionTypes.TemplatesChange: {
          return {
            ...state,
            templateStates: action.templates.map((template) => ({
              template,
              saveable: false,
              compilation: none
            }))
          };
        }
        case ActionTypes.TemplateChange: {
          return {
            ...state,
            templateStates: putTemplate(state.templateStates, action.template.name, (oldState) => ({
              template: action.template,
              saveable: action.saveable,
              compilation: none
            }))
          };
        }
        case ActionTypes.FieldRestrictedChanged: {
          return {
            ...state,
            templateStates: putTemplate(state.templateStates, action.templateName, (templateState) => ({
              ...templateState,
              template: putFieldUpdate(
                templateState.template,
                action.qualifier,
                action.field.field_name,
                action.field.is_builtin,
                { restricted: action.isRestricted }
              ),
              saveable: true
            }))
          };
        }
        case ActionTypes.FieldVisibilityChanged: {
          return {
            ...state,
            templateStates: putTemplate(state.templateStates, action.templateName, (templateState) => ({
              ...templateState,
              template: putFieldUpdate(
                templateState.template,
                action.qualifier,
                action.field.field_name,
                action.field.is_builtin,
                { private: action.isPrivate }
              ),
              saveable: true
            }))
          };
        }
        case ActionTypes.FieldLegacyLabelsChanged: {
          return {
            ...state,
            templateStates: putTemplate(state.templateStates, action.templateName, (templateState) => ({
              ...templateState,
              template: putFieldUpdate(
                templateState.template,
                action.qualifier,
                action.field.field_name,
                action.field.is_builtin,
                { legacy_labels: action.labels }
              ),
              saveable: true
            }))
          };
        }
        case ActionTypes.FieldInputTypeChanged: {
          return {
            ...state,
            templateStates: putTemplate(state.templateStates, action.templateName, (templateState) => ({
              ...templateState,
              template: putField(templateState.template, action.qualifier, action.newField),
              saveable: true
            })),
            inputValues: []
          };
        }
        case ActionTypes.FieldCompilationStarted: {
          const qualifier = action.qualifier;
          const fieldName = action.field.field_name;
          const compilation: FieldCompilationStarted = {
            type: CompilationStatus.Started,
            field_name: fieldName,
            qualifier,
            ref: action.ref
          };

          return {
            ...state,
            // why do we need the type hint here.
            // if you don't have the type hint, you can specify extra properties. I had a bug where compilation
            // was called compilations, and it didn't report an error, despite putTemplate requiring a
            // (old: TemplateState) => TemplateState function, so it should have been able to infer that
            // the return type should be a TemplateState. Only when I put the type hint there does it forbid
            // typos....what.
            templateStates: putTemplate(
              state.templateStates,
              action.templateName,
              (templateState): TemplateState => ({
                ...templateState,
                compilation: some(compilation),
                template: putField(templateState.template, action.qualifier, action.field),
                saveable: false
              })
            )
          };
        }
        case ActionTypes.FieldCompilationFailed:
          return {
            ...state,
            templateStates: putTemplate(state.templateStates, action.templateName, (templateState) => {
              if (compilationRefHasChanged(templateState.compilation, action.result)) {
                return templateState;
              }

              return {
                ...templateState,
                compilation: some(action.result),
                template: putField(templateState.template, action.qualifier, action.field),
                saveable: false
              };
            })
          };
        case ActionTypes.FieldCompilationSucceeded: {
          return {
            ...state,
            templateStates: putTemplate(state.templateStates, action.templateName, (templateState) => {
              if (compilationRefHasChanged(templateState.compilation, action.result)) {
                return templateState;
              }

              return {
                ...templateState,
                compilation: some(action.result),
                template: putField(templateState.template, action.result.qualifier, action.result.field),
                saveable: true
              };
            })
          };
        }
        case ActionTypes.FieldCompilationFatalError:
          return {
            ...state,
            templateStates: putTemplate(state.templateStates, action.templateName, (templateState) => ({
              ...templateState,
              // remove the hanging "Starting Compiling" message when there's a fatal error
              compilation: none,
              saveable: false
            }))
          };
        case ActionTypes.FieldInputValuesChanged: {
          return {
            ...state,
            inputValues: action.inputValues
          };
        }
        case ActionTypes.FieldTransformResultChanged: {
          return {
            ...state,
            transformResult: action.transformResult
          };
        }
        case ActionTypes.ScopeChange: {
          return { ...state, scope: action.scope };
        }
        case ActionTypes.ChannelConnected: {
          return { ...state, connected: true };
        }
        case ActionTypes.ChannelDisconnected: {
          return { ...state, connected: false };
        }
        case ActionTypes.UpdateFieldDefaultValue: {
          return {
            ...state,
            templateStates: putTemplate(state.templateStates, action.templateName, (templateState) => ({
              ...templateState,
              template: putFieldUpdate(
                templateState.template,
                action.qualifier,
                action.field.field_name,
                action.field.is_builtin,
                { default_value: action.defaultValue }
              ),
              saveable: true
            }))
          };
        }
        default: {
          return state;
        }
      }
    };
    const newState = doit();
    // console.log('post-action', newState);
    return newState;
  };

  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({name: 'MetadataTemplates'})) ||
    compose;
  const listenerMiddleware = (store: MiddlewareAPI<Dispatch<Actions>, AppState>) =>
    (next: (action: any) => any) =>
    (action: Actions) => {
    if (action.type === ActionTypes.TriggerAutoSave) {
      const templateState = store.getState().templateStates.find(t => t.template.name === action.templateName);
      templateState && action.doSave(templateState.template);
    } else {
      return next(action);
    }
  };
  const enhancer = composeEnhancers(applyMiddleware(listenerMiddleware));
  return createStore(reducer, initialState, enhancer);
};

export default init;
