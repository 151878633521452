import React from 'react';
import './enumeration-editor.scss';
import 'common/components/CompilerResult/compiler-result.scss';
import {
  intoMetadataComponents,
  unwrapRequiredness,
  wrapInRequiredness,
  isRequiredExpr
} from 'common/dsmapi/metadataTemplate';
import { FieldT, MetadataTemplate, MetadataType } from 'common/types/metadataTemplate';
import {
  Expr,
  SoQLStringLiteral,
  TableQualifier,
  FunCall,
  isFunCall,
  SoQLType,
  Let
} from 'common/types/soql';
import SelectEditor from './SelectEditor';
import SelectWithSelectParentEditor from './SelectWithSelectParentEditor';
import SelectWithMultiSelectParentEditor from './SelectWithMultiSelectParentEditor';
import { errorMessage } from './helpers';
import MultiSelectEditor from './MultiSelectEditor';
import MultiSelectWithParentEditor from './MultiSelectWithParentEditor';

interface EnumerationEditorProps {
  qualifier: TableQualifier;
  field: FieldT;
  updateExpr: ({
    expr,
    newLabels,
    newType
  }: {
    expr: Expr;
    newLabels?: string[];
    newType?: SoQLType;
  }) => void;
  updateLegacyLabels: (labels: string[]) => void;
  withLabels?: boolean;
  template: MetadataTemplate;
}

const EnumerationEditor: React.FunctionComponent<EnumerationEditorProps> = ({
  qualifier,
  field,
  withLabels,
  updateExpr,
  updateLegacyLabels,
  template
}) => {
  return intoMetadataComponents(
    qualifier,
    field.field_name,
    field.parsed_expr,
    field.legacy_labels,
    template
  ).match({
    none: () => null,
    some: (metadataFieldComponents) => {
      const { options, inputCref } = metadataFieldComponents;
      switch (metadataFieldComponents.type) {
        case MetadataType.dependentSelectWithSelectParent:
        case MetadataType.select: {
          const { staticCases, defaultPredicate } = metadataFieldComponents;

          const makeNewCaseEnumeration = (newCases: Expr[], newErrorMessage: string) => {
            const args: Expr[] = [
              ...staticCases,
              ...newCases,
              defaultPredicate,
              {
                type: 'funcall',
                function_name: 'error',
                args: [
                  {
                    type: 'string_literal',
                    value: newErrorMessage
                  }
                ],
                window: null
              }
            ];

            const innerCaseFun = unwrapRequiredness(field.parsed_expr, inputCref);
            if (!isFunCall(innerCaseFun)) {
              // We should never get here.
              // If we're here, then there is an error in the intoMetadataComponents which should only
              // return forms that are like:
              //
              // case(x = 'cat', 'cat', x = 'dog', 'dog', true, error('must be cat or dog'))
              //
              // OR
              //
              // case(
              //   is_empty(`required_field`),
              //   error('animal is required'),
              //   TRUE,
              //   `required_field`
              // )
              // WITH
              //   `required_field` = case(animal = 'cat', 'cat', animal = 'dog', 'dog', true, error('must be cat or dog'))
              // )
              //
              // if we somehow get here, then expr is not one of those two forms and
              // we need to think about what we've done
              throw new Error('Unwrapped required expr and the inner expr was not a case funcall!');
            }

            const caseEnum: FunCall = {
              ...innerCaseFun,
              window: null,
              args
            };

            return isRequiredExpr(field.parsed_expr, inputCref)
              ? wrapInRequiredness(field.display_name, caseEnum, inputCref)
              : caseEnum;
          };

          if (
            metadataFieldComponents.type === MetadataType.dependentSelectWithSelectParent &&
            metadataFieldComponents.parentOptions &&
            metadataFieldComponents.parentDisplayName &&
            metadataFieldComponents.parentType &&
            metadataFieldComponents.parentLabels
          ) {
            // Render the editor for a select with a parent field
            const { parentField, optionsByParent, parentOptions, parentDisplayName, parentLabels } =
              metadataFieldComponents;

            return (
              <SelectWithSelectParentEditor
                field={field}
                inputCref={inputCref}
                qualifier={qualifier}
                updateExpr={updateExpr}
                updateLegacyLabels={updateLegacyLabels}
                makeNewCaseEnumeration={makeNewCaseEnumeration}
                withLabels={withLabels}
                parentField={parentField}
                optionsByParent={optionsByParent}
                parentOptions={parentOptions}
                parentDisplayName={parentDisplayName}
                parentLabels={parentLabels}
              />
            );
          } else {
            // Render the editor for a parentless select
            const makeNewCaseEnumerationForSelect = (newOptions: string[]) => {
              const strLitOpts: SoQLStringLiteral[] = newOptions.map((opt) => ({
                type: 'string_literal',
                value: opt
              }));
              // using an empty list with IN is invalid
              const cases: Expr[] =
                newOptions.length > 0
                  ? [
                      {
                        type: 'funcall',
                        function_name: '#IN',
                        args: [inputCref, ...strLitOpts],
                        window: null
                      },
                      inputCref
                    ]
                  : [];
              return makeNewCaseEnumeration(cases, errorMessage(field, newOptions));
            };

            return (
              <SelectEditor
                field={field}
                qualifier={qualifier}
                updateExpr={updateExpr}
                updateLegacyLabels={updateLegacyLabels}
                makeNewCaseEnumeration={makeNewCaseEnumerationForSelect}
                withLabels={withLabels}
                options={options}
              />
            );
          }
        }
        case MetadataType.dependentSelectWithMultiSelectParent: {
          const {
            parentField,
            optionsByParent,
            parentOptions,
            parentDisplayName,
            parentType,
            defaultPredicate,
            staticCases,
            parentLabels
          } = metadataFieldComponents;

          if (!parentDisplayName || !parentType || !parentOptions || !parentLabels) {
            // We shouldn't ever reach here, that would mean something is very broken
            // with the logic that validates/grabs the components of the parent field
            throw new Error("Failed to deconstruct this field's parent!");
          }

          return (
            <SelectWithMultiSelectParentEditor
              field={field}
              fieldColumn={inputCref}
              parentField={parentField}
              optionsByParent={optionsByParent}
              parentOptions={parentOptions}
              parentLabels={parentLabels}
              parentDisplayName={parentDisplayName}
              withLabels={withLabels}
              updateExpr={updateExpr}
              updateLegacyLabels={updateLegacyLabels}
              defaultPredicate={defaultPredicate}
              staticCases={staticCases}
            />
          );
        }
        case MetadataType.multiSelect: {
          const makeNewMultiSelect = (editorOptions: string[]): FunCall | Let => {
            const newOptions = editorOptions.length === 1 && editorOptions[0] === '' ? [] : editorOptions;

            const stringLiteralForOptions: SoQLStringLiteral = {
              type: 'string_literal',
              value: JSON.stringify(newOptions)
            };

            const args: Expr[] = [
              {
                type: 'column_ref',
                value: field.field_name,
                qualifier
              },
              {
                type: 'funcall',
                function_name: 'cast$json',
                window: null,
                args: [stringLiteralForOptions]
              }
            ];

            const existingMultiSelectExpression = unwrapRequiredness(field.parsed_expr, inputCref);

            if (!isFunCall(existingMultiSelectExpression)) {
              // Like with the selects, we should never reach this.
              throw new Error(
                'Unwrapped required multi-select expression and the inner expression was not a FunCall!'
              );
            }

            const updatedExpression: FunCall = {
              ...existingMultiSelectExpression,
              window: null,
              args
            };

            return isRequiredExpr(field.parsed_expr, inputCref)
              ? wrapInRequiredness(field.display_name, updatedExpression, inputCref)
              : updatedExpression;
          };

          return (
            <MultiSelectEditor
              field={field}
              updateExpr={updateExpr}
              updateLegacyLabels={updateLegacyLabels}
              makeNewMultiSelect={makeNewMultiSelect}
              withLabels={withLabels}
              options={options}
            />
          );
        }
        case MetadataType.dependentMultiSelect: {
          const {
            optionsByParent,
            parentField,
            parentDisplayName,
            parentOptions,
            parentLabels,
            parentType,
            staticCases,
            defaultPredicate
          } = metadataFieldComponents;

          if (!parentDisplayName || !parentType || !parentOptions || !parentLabels) {
            // We shouldn't ever reach here, that would mean something is very broken
            // with the logic that validates/grabs the components of the parent field
            throw new Error("Failed to deconstruct this field's parent!");
          }

          return (
            <MultiSelectWithParentEditor
              field={field}
              fieldColumn={inputCref}
              parentField={parentField}
              optionsByParent={optionsByParent}
              parentOptions={parentOptions}
              parentLabels={parentLabels}
              parentDisplayName={parentDisplayName}
              parentType={parentType}
              withLabels={withLabels}
              updateExpr={updateExpr}
              updateLegacyLabels={updateLegacyLabels}
              defaultPredicate={defaultPredicate}
              staticCases={staticCases}
            />
          );
        }
        default:
          return null;
      }
    }
  });
};

export default EnumerationEditor;
