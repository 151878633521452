import React from 'react';
import FieldWithParentEditor from './FieldWithParentEditor';
import { errorMessage } from './helpers';
import {
  andPredicateBuilder,
  buildDefaultSelectWithSelectParent,
  equalsPredicateBuilder,
  inPredicateBuilder
} from 'common/dsmapi/metadataTemplate';
import {
  SoQLStringLiteral,
  TableQualifier,
  Expr,
  FunCall,
  Let,
  ColumnRef,
  SoQLType
} from 'common/types/soql';
import { FieldT, OptionByParent } from 'common/types/metadataTemplate';

export interface SelectWithSelectParentEditorProps {
  field: FieldT;
  inputCref: ColumnRef;
  qualifier: TableQualifier;
  parentField: ColumnRef;
  parentDisplayName: string;
  parentOptions: string[];
  parentLabels: string[];
  optionsByParent: OptionByParent[];
  withLabels?: boolean;
  updateLegacyLabels: (labels: string[]) => void;
  makeNewCaseEnumeration: (newCases: Expr[], newErrorMessage: string) => FunCall | Let;
  updateExpr: ({
    expr,
    newLabels,
    newType
  }: {
    expr: Expr;
    newLabels?: string[];
    newType?: SoQLType;
  }) => void;
}

const SelectWithSelectParentEditor: React.FunctionComponent<SelectWithSelectParentEditorProps> = ({
  field,
  inputCref,
  qualifier,
  updateExpr,
  updateLegacyLabels,
  makeNewCaseEnumeration,
  withLabels,
  parentField,
  optionsByParent,
  parentOptions,
  parentDisplayName,
  parentLabels
}) => {
  const makeNewCaseEnumerationForSelectWithParent = (newOptionsByParent: OptionByParent[]) => {
    const cases = newOptionsByParent.flatMap((obp) => {
      // parent == parentValue AND child in childOptions
      const parentValue: SoQLStringLiteral = { type: 'string_literal', value: obp.parentValue };
      const parentEquals = equalsPredicateBuilder(parentField.qualifier, parentField.value, parentValue);
      const childInOptions = inPredicateBuilder(inputCref, obp.options);
      return [andPredicateBuilder(parentEquals, childInOptions), inputCref];
    });
    const allOptions = newOptionsByParent.flatMap((obp) => obp.options);
    return makeNewCaseEnumeration(cases, errorMessage(field, allOptions));
  };

  return (
    <FieldWithParentEditor
      field={field}
      qualifier={qualifier}
      parentField={parentField}
      parentDisplayName={parentDisplayName}
      parentOptions={parentOptions}
      optionsByParent={optionsByParent}
      withLabels={withLabels}
      updateLegacyLabels={updateLegacyLabels}
      makeNewFieldExpression={makeNewCaseEnumerationForSelectWithParent}
      buildDefaultFieldExpression={buildDefaultSelectWithSelectParent}
      updateExpr={updateExpr}
      parentLabels={parentLabels}
    />
  );
};

export default SelectWithSelectParentEditor;
